import css from "./menu.module.css";
import classNames from "classnames";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import BonusHeader from "../../components/bonus-header/bonus-header";
import { useAuth } from "../../services/auth";

export default function Menu({ activeBonus, bonuses }) {
  const isMobile = useMediaQuery("(max-width: 1180px)");
  const isTablet = useMediaQuery("(max-width: 1280px)");
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <menu className={css.menu}>
      <div className={classNames(css.container, { container: isMobile })}>
        <div className={css.app_menu}>
          {!isMobile && (
            <div className={css.menu_link_block}>
              <NavLink to={"/free-money"}>
                <span>{t("free money")}</span>
              </NavLink>
              <NavLink to={"/ranks"}>
                <span>{t("Cashback and ranks")}</span>
              </NavLink>
              <NavLink to={"/vip"}>
                <span>{t("VIP club")}</span>
              </NavLink>
              <NavLink
                to={{ pathname: "https://garilla.partners/" }}
                target={"_blank"}
              >
                <span>{t("For partners")}</span>
              </NavLink>
            </div>
          )}
          {auth.user && !isTablet && (
            <NavLink to={!!activeBonus ? "/my-bonus" : "/my-bonus/available"}>
              <BonusHeader activeBonus={activeBonus} bonuses={bonuses} />
            </NavLink>
          )}
        </div>
      </div>
    </menu>
  );
}
