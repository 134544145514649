import css from "./user-banner.module.css";
import cash_back from "./../../images/banners/reg_log_banners/cash_back.png";
import cash_back_tablet from "./../../images/banners/reg_log_banners/cash_back_tablet.png";
import cash_back_ru from "./../../images/banners/reg_log_banners/cash_back_ru.png";
import cash_back_tablet_ru from "./../../images/banners/reg_log_banners/cash_back_tablet_ru.png";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function UserBanner() {
  const { t, i18n } = useTranslation();
  const isTablet = useMediaQuery("(max-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 767px)");
  const loginModalIsOpen = useSelector((state) => state.app.loginIsOpen);
  const registrationModalIsOpen = useSelector(
    (state) => state.app.registrationIsOpen
  );
  const [openBaner, setOpenBanner] = useState(false);
  const [registrationPageOpen, setRegistrationPageOpen] = useState(false);
  const [positionTop, setPositionTop] = useState("202px");
  const modalPosition = useSelector((state) => state.app.modalPosition);
  const ruLang = i18n?.language === "ru";

  useEffect(() => {
    window.location.pathname === "/registration"
      ? setRegistrationPageOpen(true)
      : setRegistrationPageOpen(false);
    setPositionTop(isMobile ? "116px" : isTablet ? "172px" : "202px");
  }, [isMobile, isTablet]);

  useEffect(() => {
    setOpenBanner(
      registrationPageOpen || registrationModalIsOpen || loginModalIsOpen
    );
  }, [registrationModalIsOpen, loginModalIsOpen, registrationPageOpen]);

  const bannerStyles = {
    top: `calc(${modalPosition.top}px - ${positionTop})`,
    left: `calc(${modalPosition.left}px)`,
  };

  return (
    <div
      className={classNames(css.banner, { [css.banner_open]: openBaner })}
      style={bannerStyles}
    >
      {
        ruLang? 
          <img 
            src={isTablet ? cash_back_tablet_ru : cash_back_ru }
            className={css.cash_back}
            alt="" 
          /> : 
          <img
            src={isTablet ? cash_back_tablet : cash_back}
            className={css.cash_back}
            alt=""
          />
      }
    </div>
  );
}
