import css from "./toogle-switch.module.css";
import classNames from "classnames";
import { useRef } from "react";

export default function ToogleSwitch({ checked, error, setValue, disabled }) {
  const ref = useRef();
  return (
    <div
      className={css.wrapper}
      onClick={disabled ? null : () => setValue(!checked)}
      ref={ref}
    >
      <div className={classNames(css.wrapperInner, { [css.error]: error })}>
        <div
          className={classNames(css.switcherContainer, {
            [css.active]: checked,
            [css.disabled]: disabled,
          })}
        >
          <div
            className={classNames(css.switcher, css.default, {
              [css.active]: !checked,
            })}
          ></div>
          <div
            className={classNames(css.switcher, css.checked, {
              [css.active]: checked,
            })}
          ></div>
        </div>
      </div>
    </div>
  );
}
