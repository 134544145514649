import {createApi} from "@reduxjs/toolkit/query/react";
import baseConfig from "./api.config";



export const bonusApi = createApi({
    reducerPath: "BonusApi",
    baseQuery: baseConfig,
   tagTypes: ["ActiveBonus"],
    endpoints: (builder) => ({
        getBonusSlot: builder.query({
            query: () => "/bonus-slot",
            transformResponse: response => response.data
        }),
        takeBonus: builder.mutation({
            query: ({slotId}) =>  ({
                url: `/bonus-slot/${slotId}`,
                method: "POST"
            }),
            invalidatesTags: ['ActiveBonus']
        }),
        getActiveBonus: builder.query({
            query: () => "/bonus-slot/active",
            providesTags: (result,  error, id) => {
                return ["ActiveBonus"]
            }
        }),
        cancelBonus: builder.mutation({
            query: () => ({
                url: "/bonus-slot",
                method: "DELETE"
            }),
            invalidatesTags: ['ActiveBonus']
        }),
        getHistory: builder.mutation({
            query: (filters) => {
                let filtersStr = ""
                for (let key in filters) {
                    if (filters[key]) {
                        if (filtersStr.length === 0) {
                            filtersStr += `?${key}=${filters[key]}`;
                        } else {
                            filtersStr += `&${key}=${filters[key]}`;
                        }
                    }
                }

                return `/bonus-slot/history${filtersStr}`
            }
        }),
        promoCode: builder.mutation({
            query: (data) => ({
                url: `/promo/code/activate`,
                method: "POST",
                body: data,
                transformResponse: response => response.data
            }),
        }),
    })
});

export const {
    useGetBonusSlotQuery,
    useTakeBonusMutation,
    useGetActiveBonusQuery,
    useCancelBonusMutation,
    useGetHistoryMutation,
    usePromoCodeMutation
} = bonusApi;