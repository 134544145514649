import css from "./game-layout.module.css";
import classNames from "classnames";
import close from "../../images/games/close.svg";
import { useDispatch, useSelector } from "react-redux";
import InternalError from "../../features/error/internal-error";
import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { setGame, setOpenSearchGame } from "../../features/games/game.slice";
import Scrollbar from "../../components/scrollbar/scrollbar";

export default function GameLayout({ children, game }) {
  const error500 = useSelector((state) => state.app.err500);
  const dispatch = useDispatch();
  const fullScreen = useSelector((state) => state.app.fullScreen);
  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    document.body.classList.add("game-page");

    return () => document.body.classList.remove("game-page");
  }, []);

  const scrollbarRef = useRef();
  const valuesScroll = useSelector((state) => state.app.valuesScroll);
  const prevScrollTopRef = useRef(0);
  const filters = useSelector((state) => state.game.filters);

  const handleScroll = () => {
    const { scrollTop } = valuesScroll;

    if (
      valuesScroll.scrollTop <= 60 &&
      valuesScroll.scrollTop < prevScrollTopRef.current
    ) {
      dispatch(setOpenSearchGame(true));
    } else {
      dispatch(setOpenSearchGame(false));
    }

    prevScrollTopRef.current = scrollTop;
  };

  useEffect(() => {
    handleScroll();
  }, [valuesScroll.scrollTop]);

  if (isMobile) {
    return error500 ? (
      <InternalError />
    ) : (
      <div className={classNames(css.layout, css.mobile)}>{children}</div>
    );
  }

  return (
    <div className={css.layout}>
      <div
        className={classNames(css.wrapper, { [css.fullScreen]: fullScreen })}
      >
        <Scrollbar ref={scrollbarRef} onScroll={handleScroll}>
          <div
            className={classNames(css.container, {
              [css.fullScreen]: fullScreen,
            })}
          >
            <div className={css.content}>
              <div className={css.fakeCloseContainer}>
                {!isMobile && (
                  <span
                    className={classNames(css.close_icon, "ignore-active", {
                      [css.fullScreen]: fullScreen,
                    })}
                    onClick={() => {
                      localStorage.setItem("filters", JSON.stringify(filters));
                      window.location.reload();
                      dispatch(setGame(null));
                    }}
                  >
                    <img src={close} alt={""} />
                  </span>
                )}
              </div>
              {error500 ? <InternalError /> : children}
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}
