import ContentPane from "../../components/content-pane/content-pane";
import { useTranslation } from "react-i18next";
import css from "./ranks.module.css";
import React, { useState } from "react";
import classNames from "classnames";
import playerCard from "./../../images/ranks/cards ranks/playerCard.svg";
import knightCard from "./../../images/ranks/cards ranks/knightCard.svg";
import baronCard from "./../../images/ranks/cards ranks/baronCard.svg";
import viscountCard from "./../../images/ranks/cards ranks/viscountCard.svg";
import earlCard from "./../../images/ranks/cards ranks/earlCard.svg";
import marquisCard from "./../../images/ranks/cards ranks/marquisCard.svg";
import dukeCard from "./../../images/ranks/cards ranks/dukeCard.svg";
import kingCard from "./../../images/ranks/cards ranks/kingCard.svg";
import godCard from "./../../images/ranks/cards ranks/godCard.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import { EffectCoverflow, Autoplay, Navigation } from "swiper/modules";

export default function Ranks() {
  const { t } = useTranslation();

  const swiperOptions = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    loop: true,
    // slidesPerView: 1.1,
    slidesPerView: "auto",
    navigation: true,
    // autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false,
    //     pauseOnMouseEnter: true
    // },
    coverflowEffect: {
      rotate: 0,
      stretch: "-70%",
      // depth: 250,
      modifier: -1,
      slideShadows: false,
    },
  };

  const sliders_ru = [
    {
      id: 0,
      name: t("Player"),
      deposit_amount: "0 RUB",
      cashback: "2%",
      birthday_gift: "1 000 RUB",
      ny_gift: t("No"),
      withdrawal_day: "200 000 RUB",
      withdrawal_per_week: "450 000 RUB",
      withdrawal_per_month: "1 000 000 RUB",
    },
    {
      id: 1,
      name: t("Knight"),
      deposit_amount: "400 000 RUB",
      cashback: "3%",
      birthday_gift: "2 000 RUB",
      ny_gift: t("No"),
      withdrawal_day: "300 000 RUB",
      withdrawal_per_week: "1 000 000 RUB",
      withdrawal_per_month: "2 500 000 RUB",
    },
    {
      id: 2,
      name: t("Baron"),
      deposit_amount: "650 000 RUB",
      cashback: "5%",
      birthday_gift: "8 000 RUB",
      ny_gift: t("No"),
      withdrawal_day: "450 000 RUB",
      withdrawal_per_week: "1 300 000 RUB",
      withdrawal_per_month: "3 100 000 RUB",
    },
    {
      id: 3,
      name: t("Viscount"),
      deposit_amount: "900 000 RUB",
      cashback: "7%",
      birthday_gift: "15 000 RUB",
      ny_gift: t("No"),
      withdrawal_day: "900 000 RUB",
      withdrawal_per_week: "1 800 000 RUB",
      withdrawal_per_month: "6 500 000 RUB",
    },
    {
      id: 4,
      name: t("Earl"),
      deposit_amount: "4 500 000 RUB",
      cashback: "10%",
      birthday_gift: "20 000 RUB",
      ny_gift: "5 000 RUB",
      withdrawal_day: "4 500 000 RUB",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 5,
      name: t("Marquis"),
      deposit_amount: "7 000 000 RUB",
      cashback: "10%",
      birthday_gift: "40 000 RUB",
      ny_gift: "8 000 RUB",
      withdrawal_day: "7 000 000 RUB",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 6,
      name: t("Duke"),
      deposit_amount: "10 000 000 RUB",
      cashback: "10%",
      birthday_gift: "60 000 RUB",
      ny_gift: "10 000 RUB",
      withdrawal_day: "10 000 000 RUB",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 7,
      name: t("King"),
      deposit_amount: "15 000 000 RUB",
      cashback: "10%",
      birthday_gift: "80 000 RUB",
      ny_gift: "15 000 RUB",
      withdrawal_day: "15 000 000 RUB",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 8,
      name: t("God"),
      deposit_amount: "25 000 000 RUB",
      cashback: "10%",
      birthday_gift: "100 000 RUB",
      ny_gift: "20 000 RUB",
      withdrawal_day: "25 000 000 RUB",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
  ];

  const sliders_kzt = [
    {
      id: 0,
      name: t("Player"),
      deposit_amount: "0 ₸",
      cashback: "2%",
      birthday_gift: "4 800 ₸",
      withdrawal_day: "950 000 ₸",
      withdrawal_per_week: "2 150 000 ₸",
      withdrawal_per_month: "4 800 000 ₸",
    },
    {
      id: 1,
      name: t("Knight"),
      deposit_amount: "1 900 000 ₸",
      cashback: "3%",
      birthday_gift: "9 800 ₸",
      withdrawal_day: "1 450 000 ₸",
      withdrawal_per_week: "4 800 000 ₸",
      withdrawal_per_month: "12 000 000 ₸",
    },
    {
      id: 2,
      name: t("Baron"),
      deposit_amount: "3 100 000 ₸",
      cashback: "5%",
      birthday_gift: "40 000 ₸",
      withdrawal_day: "2 150 000 ₸",
      withdrawal_per_week: "6 300 000 ₸",
      withdrawal_per_month: "15 000 000 ₸",
    },
    {
      id: 3,
      name: t("Viscount"),
      deposit_amount: "4 300 000 ₸",
      cashback: "7%",
      birthday_gift: "70 000 ₸",
      withdrawal_day: "4 300 000 ₸",
      withdrawal_per_week: "8 700 000 ₸",
      withdrawal_per_month: "31 500 000 ₸",
    },
    {
      id: 4,
      name: t("Earl"),
      deposit_amount: "21 500 000 ₸",
      cashback: "10%",
      birthday_gift: "98 000 ₸",
      withdrawal_day: "21 500 000 ₸",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 5,
      name: t("Marquis"),
      deposit_amount: "33 500 000 ₸",
      cashback: "10%",
      birthday_gift: "195 000 ₸",
      withdrawal_day: "33 500 000  ₸",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 6,
      name: t("Duke"),
      deposit_amount: "48 000 000 ₸",
      cashback: "10%",
      birthday_gift: "295 000 ₸",
      withdrawal_day: "48 000 000 ₸",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 7,
      name: t("King"),
      deposit_amount: "71 000 000 ₸",
      cashback: "10%",
      birthday_gift: "490 000 ₸",
      withdrawal_day: "71 500 000  ₸",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 8,
      name: t("God"),
      deposit_amount: "480 000 000 ₸",
      cashback: "10%",
      birthday_gift: "490 000 ₸",
      withdrawal_day: "119 000 000 ₸",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
  ];

  const sliders_azn = [
    {
      id: 0,
      name: t("Player"),
      deposit_amount: "0 ₼",
      cashback: "2%",
      birthday_gift: "19 ₼",
      withdrawal_day: "4 000 ₼",
      withdrawal_per_week: "8 500 ₼",
      withdrawal_per_month: "18 000 ₼",
    },
    {
      id: 1,
      name: t("Knight"),
      deposit_amount: "7 500 ₼",
      cashback: "3%",
      birthday_gift: "38 ₼",
      withdrawal_day: "6 000 ₼",
      withdrawal_per_week: "18 000 ₼",
      withdrawal_per_month: "46 000 ₼",
    },
    {
      id: 2,
      name: t("Baron"),
      deposit_amount: "12 000 ₼",
      cashback: "5%",
      birthday_gift: "150 ₼",
      withdrawal_day: "8 500 ₼",
      withdrawal_per_week: "24 000 ₼",
      withdrawal_per_month: "58 000 ₼",
    },
    {
      id: 3,
      name: t("Viscount"),
      deposit_amount: "17 000 ₼",
      cashback: "7%",
      birthday_gift: "280 ₼",
      withdrawal_day: "17 000 ₼",
      withdrawal_per_week: "33 000 ₼",
      withdrawal_per_month: "121 000 ₼",
    },
    {
      id: 4,
      name: t("Earl"),
      deposit_amount: "21 500 000 ₼",
      cashback: "10%",
      birthday_gift: "380 ₼",
      withdrawal_day: "84 000 ₼",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 5,
      name: t("Marquis"),
      deposit_amount: "33 500 000 ₼",
      cashback: "10%",
      birthday_gift: "760 ₼",
      withdrawal_day: "130 000  ₼",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 6,
      name: t("Duke"),
      deposit_amount: "48 000 000 ₼",
      cashback: "10%",
      birthday_gift: "1 100 ₼",
      withdrawal_day: "130 000 ₼",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 7,
      name: t("King"),
      deposit_amount: "71 000 000 ₼",
      cashback: "10%",
      birthday_gift: "1 100 ₼",
      withdrawal_day: "280 000  ₼",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 8,
      name: t("God"),
      deposit_amount: "480 000 000 ₼",
      cashback: "10%",
      birthday_gift: "1 900 ₼",
      withdrawal_day: "500 000 ₼",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
  ];

  const sliders_kgs = [
    {
      id: 0,
      name: t("Player"),
      deposit_amount: "0 C",
      cashback: "2%",
      birthday_gift: "1 000 C",
      withdrawal_day: "195 000 C",
      withdrawal_per_week: "440 000 C",
      withdrawal_per_month: "970 000 C",
    },
    {
      id: 1,
      name: t("Knight"),
      deposit_amount: "390 000 C",
      cashback: "3%",
      birthday_gift: "2 000 C",
      withdrawal_day: "290 000 C",
      withdrawal_per_week: "1 390 000 C",
      withdrawal_per_month: "2 400 000 C",
    },
    {
      id: 2,
      name: t("Baron"),
      deposit_amount: "12 000 C",
      cashback: "5%",
      birthday_gift: "8 000 C",
      withdrawal_day: "440 000 C",
      withdrawal_per_week: "1 250 000 C",
      withdrawal_per_month: "3 000 000 C",
    },
    {
      id: 3,
      name: t("Viscount"),
      deposit_amount: "17 000 C",
      cashback: "7%",
      birthday_gift: "15 000 C",
      withdrawal_day: "880 000 C",
      withdrawal_per_week: "1 750 000 C",
      withdrawal_per_month: "6 300 000 C",
    },
    {
      id: 4,
      name: t("Earl"),
      deposit_amount: "21 500 000 C",
      cashback: "10%",
      birthday_gift: "20 000 C",
      withdrawal_day: "4 400 000 C",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 5,
      name: t("Marquis"),
      deposit_amount: "33 500 000 C",
      cashback: "10%",
      birthday_gift: "40 000 C",
      withdrawal_day: "6 800 000  C",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 6,
      name: t("Duke"),
      deposit_amount: "48 000 000 C",
      cashback: "10%",
      birthday_gift: "6 800 000 C",
      withdrawal_day: "1 450 000 C",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 7,
      name: t("King"),
      deposit_amount: "71 000 000 C",
      cashback: "10%",
      birthday_gift: "60 000 C",
      withdrawal_day: "14 500 000  C",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 8,
      name: t("God"),
      deposit_amount: "480 000 000 C",
      cashback: "10%",
      birthday_gift: "100 000 C",
      withdrawal_day: "24 000 000 C",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
  ];

  const sliders_uzs = [
    {
      id: 0,
      name: t("Player"),
      deposit_amount: "0 Cўм",
      cashback: "2%",
      birthday_gift: "140 000 Cўм",
      withdrawal_day: "28 000 000 Cўм",
      withdrawal_per_week: "63 000 000 Cўм",
      withdrawal_per_month: "139 000 000 Cўм",
    },
    {
      id: 1,
      name: t("Knight"),
      deposit_amount: "56 000 000 Cўм",
      cashback: "3%",
      birthday_gift: "280 000 Cўм",
      withdrawal_day: "42 000 000 Cўм",
      withdrawal_per_week: "139 000 000 Cўм",
      withdrawal_per_month: "347 000 000 Cўм",
    },
    {
      id: 2,
      name: t("Baron"),
      deposit_amount: "90 000 000 Cўм",
      cashback: "5%",
      birthday_gift: "1 120 000 Cўм",
      withdrawal_day: "63 000 000 Cўм",
      withdrawal_per_week: "180 000 000 Cўм",
      withdrawal_per_month: "341 000 000 Cўм",
    },
    {
      id: 3,
      name: t("Viscount"),
      deposit_amount: "126 000 000 Cўм",
      cashback: "7%",
      birthday_gift: "2 000 000 Cўм",
      withdrawal_day: "126 000 000  Cўм",
      withdrawal_per_week: "250 000 000 Cўм",
      withdrawal_per_month: "903 000 000 Cўм",
    },
    {
      id: 4,
      name: t("Earl"),
      deposit_amount: "630 000 000 Cўм",
      cashback: "10%",
      birthday_gift: "5 600 000 Cўм",
      withdrawal_day: "630 000 000 Cўм",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 5,
      name: t("Marquis"),
      deposit_amount: "980 000 000 Cўм",
      cashback: "10%",
      birthday_gift: "8 400 000 Cўм",
      withdrawal_day: "980 000 000   Cўм",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 6,
      name: t("Duke"),
      deposit_amount: "1 400 000 000 Cўм",
      cashback: "10%",
      birthday_gift: "14 000 000 Cўм",
      withdrawal_day: "980 000 000 Cўм",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 7,
      name: t("King"),
      deposit_amount: "2 100 000 000 Cўм",
      cashback: "10%",
      birthday_gift: "60 000 Cўм",
      withdrawal_day: "2 100 000 000  Cўм",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
    {
      id: 8,
      name: t("God"),
      deposit_amount: "3 500 000 000 Cўм",
      cashback: "10%",
      birthday_gift: "100 000 Cўм",
      withdrawal_day: "3 500 000 000 Cўм",
      withdrawal_per_week: t("no Limit"),
      withdrawal_per_month: t("no Limit"),
    },
  ];

  const slidersMap = {
    kzt: sliders_kzt,
    azn: sliders_azn,
    kgs: sliders_kgs,
    uzs: sliders_uzs,
  };

  const subdomain = window.location.hostname.split(".")[0];
  const localRanks = subdomain.length > 3 ? sliders_ru : slidersMap[subdomain];

  const [activeSlide, setActiveSlide] = useState(0);

  const slideTo = (e) => {
    setActiveSlide(e.realIndex);
  };

  return (
    <div className={css.ranks_background}>
      <ContentPane className={css.ranks_wraper}>
        <div className={css.ranks_wraper__container}>
          <div className={css.ranks_border}></div>
          <div className={css.ranks_cards}>
            <Swiper
              {...swiperOptions}
              modules={[EffectCoverflow, Autoplay, Navigation]}
              className={classNames(css.swiper, "ranks-swiper")}
              onSlideChange={(e) => slideTo(e)}
            >
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={playerCard} alt="playerCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Player")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={knightCard} alt="knightCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Knight")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={baronCard} alt="baronCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Baron")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={viscountCard} alt="viscountCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Viscount")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={earlCard} alt="earlCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Earl")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={marquisCard} alt="marquisCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Marquis")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={dukeCard} alt="dukeCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("Duke")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={kingCard} alt="kingCard" />
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("King")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={css.slider}>
                  <img src={godCard} alt="godCard" />
                  {/*<img src={auth.user.rank === null? godCard : (auth.user.rank_id === 9 || auth.user.rank_id > 9 ? godCard : godCardLock)} alt="godCard" />*/}
                  <div className={css.slider_card}>
                    <div className={css.slider_card__block}></div>
                    <div className={css.slider_card__blockBootom}>
                      <span className={css.carusel_slider__player}>
                        {t("God")}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={css.ranks_progress}>
            <div className={css.rank_name_title}>
              <div className={css.rank_name_block}>
                <div className={css.rank_name}>
                  {localRanks[activeSlide].name}
                </div>
                {activeSlide >= 4 ? (
                  <div
                    className={classNames(
                      css.rank_name_vip_club,
                      css.rank_name_vip_club_show
                    )}
                  >
                    {t("vip club")}
                  </div>
                ) : (
                  <div className={css.rank_name_vip_club}>{t("vip club")}</div>
                )}
              </div>
              <div className={css.rank_name_description}>
                {t("Condition")}: {t("deposits amount")}&nbsp;
                <>{localRanks[activeSlide].deposit_amount}</>
              </div>
            </div>
            <div className={css.rank_block_info}>
              <div className={css.rank_block_info_title}>{t("Advantages")}</div>
              <div className={css.rank_block_items}>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("cashback")}
                  </div>
                  <div className={css.rank_block_item_val}>
                    {localRanks[activeSlide].cashback}
                  </div>
                </div>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("birthday gift")}
                  </div>
                  <div className={css.rank_block_item_val}>
                    {localRanks[activeSlide].birthday_gift}
                  </div>
                </div>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("Accelerated withdrawal")}
                  </div>
                  {(activeSlide < 4 && (
                    <div className={css.rank_block_item_val}>{t("No")}</div>
                  )) ||
                    (activeSlide > 3 && (
                      <div className={css.rank_block_item_val}>{t("Yes")}</div>
                    ))}
                </div>

                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("support 24/7")}
                  </div>
                  <div className={css.rank_block_item_val}>{t("Yes")}</div>
                </div>
                {localRanks[activeSlide].ny_gift && (
                  <div className={css.rank_block_item}>
                    <div className={css.rank_block_item_name}>
                      {t("new year's gift")}
                    </div>
                    <div className={css.rank_block_item_val}>
                      {localRanks[activeSlide].ny_gift}
                    </div>
                  </div>
                )}
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("personal manager")}
                  </div>
                  {(activeSlide < 4 && (
                    <div className={css.rank_block_item_val}>{t("No")}</div>
                  )) ||
                    (activeSlide > 3 && (
                      <div className={css.rank_block_item_val}>{t("Yes")}</div>
                    ))}
                </div>
              </div>
            </div>

            <div className={css.rank_block_info}>
              <div className={css.rank_block_info_title}>{t("bonuses")}</div>
              <div className={css.rank_block_items}>
                <div
                  className={classNames(
                    css.rank_block_item,
                    css.rank_block_item_big
                  )}
                >
                  <div className={css.rank_block_item_name}>
                    {t("Weekly Bonus")}
                  </div>
                  <div className={css.rank_block_item_val}>{t("Yes")}</div>
                </div>
                <div
                  className={classNames(
                    css.rank_block_item,
                    css.rank_block_item_big
                  )}
                >
                  <div className={css.rank_block_item_name}>
                    {t("Additional bonus")}
                  </div>
                  {(activeSlide < 4 && (
                    <div className={css.rank_block_item_val}>{t("No")}</div>
                  )) ||
                    (activeSlide > 3 && (
                      <div className={css.rank_block_item_val}>{t("Yes")}</div>
                    ))}
                </div>
              </div>
            </div>

            <div className={css.rank_block_info}>
              <div className={css.rank_block_info_title}>
                {t("Withdrawal limits")}
              </div>
              <div className={css.rank_block_items}>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("Withdrawal per day")}
                  </div>
                  <div className={css.rank_block_item_val}>
                    {t("up to")} {localRanks[activeSlide].withdrawal_day}
                  </div>
                </div>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("Withdrawal per week")}
                  </div>
                  <div className={css.rank_block_item_val}>
                    {activeSlide < 4 ? t("up to") : ""}{" "}
                    {localRanks[activeSlide].withdrawal_per_week}
                  </div>
                </div>
                <div className={css.rank_block_item}>
                  <div className={css.rank_block_item_name}>
                    {t("Withdrawal per month")}
                  </div>
                  <div className={css.rank_block_item_val}>
                    {activeSlide < 4 ? t("up to") : ""}{" "}
                    {localRanks[activeSlide].withdrawal_per_month}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentPane>
    </div>
  );
}
