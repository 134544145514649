import css from "./language-drop.module.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/dropdown/dropdown";
import { useDispatch } from "react-redux";
import { setOverlaySidebar } from "./../../../app/app.slice";
import React, { useState, useEffect } from "react";
import flag_ru from "./../../../images/local/ru.svg";
import flag_en from "./../../../images/local/gb.svg";
import flag_kz from "./../../../images/local/kz.svg";
import flag_az from "./../../../images/local/az.svg";
import flag_uz from "./../../../images/local/uz.svg";
import flag_kg from "./../../../images/local/kg.svg";
import arrow from "./../../../images/local/arrow.svg";

export default function LanguageDrop() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [langSelectorOpened, setLangSelectorOpened] = useState(false);
  const language =
    i18n.language.indexOf("-") > 0
      ? i18n.language.split("-")[0].toLowerCase()
      : i18n.language;

  const languages = {
    en: { name: "En", flag: flag_en },
    ru: { name: "Ru", flag: flag_ru },
    kz: { name: "Kz", flag: flag_kz },
    az: { name: "Az", flag: flag_az },
    kg: { name: "Kg", flag: flag_kg },
    uz: { name: "Uz", flag: flag_uz },
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLangSelectorOpened(!langSelectorOpened);
    dispatch(setOverlaySidebar(false));
  };

  useEffect(() => {
    if (!langSelectorOpened) {
      dispatch(setOverlaySidebar(false));
    }
    // eslint-disable-next-line
  }, [langSelectorOpened]);

  return (
    <div className={css.menu}>
      <div className={css.sub_container}>
        <Dropdown
          className={css.sub}
          expanded={langSelectorOpened}
          setExpanded={setLangSelectorOpened}
        >
          {Object.keys(languages).map((lang, index) => (
            <div key={lang} className={css[lang]}>
              <div
                className={`${css.lang} ${
                  index === Object.keys(languages).length - 1 ? css.last : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage(lang);
                }}
                href="#"
              >
                <img src={languages[lang].flag} alt={languages[lang].name} />
                {languages[lang].name}
              </div>
            </div>
          ))}
        </Dropdown>
      </div>
      <div
        onClick={() => {
          setLangSelectorOpened(!langSelectorOpened);
          dispatch(setOverlaySidebar(true));
        }}
        className={classNames(css.regions, css[language])}
        href="#"
      >
        {language}
        <div
          className={classNames(css.arrow, {
            [css.rotate]: !langSelectorOpened,
          })}
        >
          <img src={arrow} alt="" />
        </div>
      </div>
    </div>
  );
}
