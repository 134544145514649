import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";

const getLanguageFromSubdomain = () => {
  const subdomain = window.location.hostname.split(".")[0];
  switch (subdomain) {
    case "kzt":
      return "kz";
    case "azn":
      return "az";
    case "kgs":
      return "kg";
    case "uzs":
      return "uz";
    default:
      return "ru";
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language, namespace) =>
        import(`./locales/${language}/${namespace}_v1.3.json`)
    )
  )
  .init({
    lng: getLanguageFromSubdomain(),
    fallbackLng: {
      ru: ["ru"],
      en: ["en"],
      kz: ["kz"],
      uz: ["uz"],
      az: ["az"],
      kg: ["kg"],
    },
    load: "currentOnly",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
