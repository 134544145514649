import css from "./footer.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FooterMain from "./main/main";
import { useMediaQuery } from "beautiful-react-hooks";

export default function Footer() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 960px)");

  return (
    <footer className={css.footer}>
      <FooterMain />
      <div className={classNames(css.copyright_info)}>
        <div className={classNames(css.container, css.copyright_info__text)}>
          {t("copyright_info", { currentYear: new Date().getFullYear() })}
        </div>
      </div>
    </footer>
  );
}
