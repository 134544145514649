import css from "./wrapper.module.css";
import ContentPane from "../../../components/content-pane/content-pane";
import React from "react";
import {useTranslation} from "react-i18next";

export default function GamesWrapper({isUninitialized, isFetching, children}) {
    const {t} = useTranslation();

    return <>
        <div className="container-2">
            {
                children
            }
        </div>
        {
            !isUninitialized && !children && !isFetching && <ContentPane className={css.has_no_items}>
                {t("Unfortunately, there are no matching games.")}
            </ContentPane>
        }
    </>
}