import css from "./game.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import detectDevice from "../../../utils/detect-device";
import Loader from "../../../components/loader/Loader";
import err500 from "../../../images/items/Page-Error.png";
import { useAuth } from "../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import {
  setClickLogo,
  setFilters,
  setGame,
  setStopGamesFilters,
} from "../game.slice";
import { setFullScreen } from "../../../app/app.slice";
import fullScreen_img from "../../../images/games/fullScreen.svg";
import addToFavorite from "../../../images/games/addToFavorite.svg";
import addToFavoriteActive from "../../../images/games/addToFavoriteActive.svg";
import garillaMain from "../../../images/games/garilla_game_main.svg";
import ToogleSwitch from "../../../components/toggle-switch/toogle-switch";
import close from "../../../images/games/garilla_close.svg";
// import Draggable from "react-draggable";
import { GAMES_LIST_OUR_PLAYING } from "../config";
import {useFavoriteMutation, useGameSessionMutation, useGetGamesQuery} from "../../../app/api/games.api";

const TARGET = "game-container";

export default function Game(item, action) {
  const game = useSelector((state) => state.game.game);
  const [gameSession, { data, error, isLoading, isUninitialized }] =
    useGameSessionMutation();
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fullGamesList, setGamesList] = useState([]);
  const fullScreen = useSelector((state) => state.app.fullScreen);
  const { t } = useTranslation();
  const [selectedGame, setSelectedGame] = useState(game);
  const auth = useAuth();
  const dispatch = useDispatch();
  const deviceInfo = new detectDevice();
  const {
    data: allGames,
    // eslint-disable-next-line
    isFetching,
    // eslint-disable-next-line
    error: gamesError,
  } = useGetGamesQuery({ device: deviceInfo.type, page: currentPage });
  const [favoriteAction, { isLoadingFavorite, error: favoriteError }] =
    useFavoriteMutation();
  const [favorite, setFavorite] = useState(item.favorite);
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1180px)");

  const isLiveGame = () => item.category !== "slots";

  const [position, setPosition] = useState({ x: 0, y: 200 });
  const [isDragging, setIsDragging] = useState(false);
  const buttonRef = React.createRef();

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const button = buttonRef.current;
      const { clientY } = event;
      const rect = button.getBoundingClientRect();
      const offsetY = clientY - rect.height / 2;

      setPosition({ y: offsetY });
    }
  };

  useEffect(() => {
    if (favoriteError) {
      setFavorite(!favorite);
    }
    // eslint-disable-next-line
  }, [favoriteError]);

  const isMobile = useMediaQuery("(max-width: 768px)") || deviceInfo.isMobile;
  const errorHandler = (text) => {
    const notify = () =>
      toast(text, {
        theme: "dark",
        type: "error",
      });
    notify();
    setErrorMessage(text);
    if (isMobile || isTablet) {
      dispatch(setGame(null));
      window.closeSplash();
    }
  };
  const errorCodes = ["502", "101", "404", "405"];

  useEffect(() => {
    if (allGames) {
      setGamesList(fullGamesList.concat(allGames.data));
    }
    // eslint-disable-next-line
  }, [allGames]);

  useEffect(() => {
    if (isUninitialized) {
      //load first time
      const params = {
        body: { gameId: game.id },
        demo: game.mode === "demo",
      };
      gameSession(params);
      setSelectedGame(game);
    }
    // eslint-disable-next-line
  }, [isUninitialized]);
  useEffect(() => {
    if (
      !isUninitialized &&
      !isLoading &&
      (selectedGame.id !== game.id || selectedGame.mode !== game.mode)
    ) {
      //refetch when select new game
      const params = {
        body: { gameId: game.id },
        demo: game.mode === "demo",
      };
      gameSession(params);
      setSelectedGame(game);
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    if (error?.status === 409) {
      errorHandler(t("This game is not available with an activated bonus"));
    }
    if (error) {
      console.error(error);
      dispatch(setGame(null));
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (data /* && !data.gameUrl*/) {
      // const strategies = [
      //   "redirect",
      //   "iframe",
      //   "detect",
      //   "detect_device",
      //   "netent",
      //   "playngo",
      //   "gaming1",
      //   "elk",
      // ];

      if (errorCodes.includes(data.code)) {
        errorHandler(data.message ? data.message : t("Error"));
        dispatch(setGame(null));
      } else {
        if (data.strategy /*strategies.includes(data.strategy)*/) {
          if (deviceInfo.isSafariIOS) {
            //ios safari crutch
            dispatch(setGame(null));
          }

          if (GAMES_LIST_OUR_PLAYING.includes(game.provider) && isMobile) {
            const mobileUrl = data.mobile_url;
            const redirectUrl = `${mobileUrl}&return_url=${encodeURIComponent(
              window.location.origin
            )}`;

            try {
              window.location.href = redirectUrl;
            } catch (e) {
              console.error(`Can't push location state "${redirectUrl}"`);
            }
          } else {
            window.sg.launch(
              {
                target_element: TARGET,
                launch_options: JSON.parse(JSON.stringify(data)),
              },
              (e) => {
                console.log(`SUCCESS ${selectedGame.id},`);
                window.closeSplash();
              },
              (err) => {
                dispatch(setGame(null));
                console.error(`ERROR ${selectedGame.id},`, err);
              }
            );
          }
        } else {
          dispatch(setGame(null));
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div
      className={classNames(css.game, {
        [css.fullScreen]: fullScreen,
        [css.disable]: deviceInfo.type !== "desktop",
        [css.game_amatic]:
          game?.provider === "amatic" || game?.provider === "netent",
      })}
    >
      {!!errorMessage && (
        <div className={css.gameError}>
          <div>{t("Unfortunately the game is not available")}</div>
          <div>
            {t("error text:")} <b>{errorMessage}</b>
          </div>
          <div
            onClick={() => {
              dispatch(setGame(null));
              window.closeSplash();
            }}
            className={css.goBack}
          >
            {t("Go backward")}
          </div>
        </div>
      )}
      {data && !isLoading && !error && !errorCodes.includes(data?.code) ? (
        // || isMobile
        <div className={css.main_container} id={TARGET} />
      ) : (
        (!!error || errorCodes.includes(data?.code)) &&
        !isLoading && (
          <div
            className={classNames(css.main_container, css.error, {
              [css.mobile]: isMobile,
            })}
          >
            <img
              className={classNames({ [css.mobile]: isMobile })}
              src={err500}
              alt=""
            />
          </div>
        )
      )}
      {(isLoading || (!error && !data)) && (
        <Loader
          className={classNames(css.main_container, { [css.mobile]: isMobile })}
        />
      )}
      {fullScreen ? (
        <span
          ref={buttonRef}
          style={{ position: "absolute", top: position.y }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          // onMouseOut={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchMove={(event) => handleMouseMove(event.touches[0])}
          className={classNames(css.close_icon, "ignore-active")}
          onClick={() => dispatch(setFullScreen(false))}
        >
          <img src={close} alt={""} />
        </span>
      ) : (
        // <Draggable axis="y">
        //     <div className={css.close_icon}>
        //         <img src={close} alt="" />
        //     </div>
        // </Draggable>
        <div
          className={classNames(css.sidebar, { [css.fullScreen]: fullScreen })}
        >
          <div className={css.actions}>
            <div className={css.actions_block}>
              <span
                classNames={css.actions_block__fullScreen}
                onClick={() => {
                  dispatch(setFullScreen(true));
                }}
              >
                {/*<span className={classNames(css.action_fullScreen__text, css.action_fullScreen__hiden, {[css.fullScreen]: fullScreen})}>{t("full screen")}</span>*/}
                <img src={fullScreen_img} alt="" />
              </span>
              {auth?.user && (
                <span
                  onClick={() => {
                    if (!isLoadingFavorite) {
                      setFavorite(!favorite);
                      favoriteAction({ gameId: item.game.id });
                    }
                  }}
                  className={classNames(css.favorite_block, {
                    [css.inFavorite]: favorite,
                    [css.live]: isLiveGame(),
                  })}
                >
                  {/*<span className={classNames(css.action_favorite__text, css.action_favorite__hiden)}>{t("Add to favorites")}</span>*/}
                  <img
                    className={classNames(css.favorite1, {
                      [css.fullScreen]: fullScreen,
                    })}
                    src={addToFavorite}
                    alt=""
                  />
                  <img
                    className={classNames(css.favorite, css.hover)}
                    src={addToFavoriteActive}
                    onClick={() =>
                      action("favorite", item.identifier, item.provider)
                    }
                    alt=""
                  />
                  <img
                    className={classNames(css.favorite, css.active)}
                    src={addToFavoriteActive}
                    onClick={() =>
                      action("favorite", item.identifier, item.provider)
                    }
                    alt=""
                  />
                </span>
              )}
            </div>
            <span
              className={classNames(css.actions_garilla, {
                [css.fullScreen]: fullScreen,
              })}
              onClick={() => {
                window.location.reload();
                dispatch(setGame(null));
                dispatch(setFilters(null));
                dispatch(setStopGamesFilters(true));
                dispatch(setClickLogo(true));
              }}
            >
              <img src={garillaMain} alt="" />
            </span>
            <div
              className={classNames(
                css.actions_demoBlock,
                css.actions_demoBlock__pointer
              )}
            >
              <span
                onClick={() => dispatch(setGame({ ...game, mode: "demo" }))}
                className={
                  game.mode === "demo" ? css.actions_demoBlock__active : ""
                }
              >
                {t("Demo")}
              </span>
              <div className={css.toogleSwitch}>
                <ToogleSwitch
                  checked={game.mode === "play"}
                  setValue={(play) => {
                    if (play) {
                      dispatch(setGame({ ...game, mode: "play" }));
                    } else {
                      dispatch(setGame({ ...game, mode: "demo" }));
                    }
                  }}
                />
              </div>
              <span
                className={
                  game.mode === "demo" ? "" : css.actions_demoBlock__active
                }
                onClick={() => dispatch(setGame({ ...game, mode: "play" }))}
              >
                {t("Money")}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
