import css from "./main-footer.module.css";
import footer_style from "../footer.module.css";
import logo from "../../../images/logo.png";
import { Link, NavLink } from "react-router-dom";
import React, { useMemo } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { useTranslation } from "react-i18next";
import adults from "../../../images/footer-icons/18+.svg";
import iTechLabs from "../../../images/footer-icons/iTechLabs.png";
import gameCare from "../../../images/footer-icons/CamCare.png";
import gameCuracao from "../../../images/footer-icons/GamingCuracao.svg";
import beGambleAware from "../../../images/footer-icons/beGambleAware.svg";
import classNames from "classnames";
import SupportBlock from "../../../containers/layout/support-block/support-block";
import PhoneCard from "../phone-card/phone-card";
import iphone from "./../../../images/iphone.svg";
import android from "./../../../images/android.svg";

import telegram from "./../../../images/footer-icons/telegram_default.svg";
import telegram_hover from "./../../../images/footer-icons/telegram_hover.svg";
import instagram from "./../../../images/footer-icons/instagram_default.svg";
import instagram_hover from "./../../../images/footer-icons/instagram_hover.svg";

import vk_png from "./../../../images/footer-icons/png/vk_png.png";
import vk_hover_png from "./../../../images/footer-icons/png/vk_hover_png.png";
import instagram_png from "./../../../images/footer-icons/png/instagram_png.png";
import instagram_hover_png from "./../../../images/footer-icons/png/instagram_hover_png.png";
import telegram_png from "./../../../images/footer-icons/png/telegram_png.png";
import telegram_hover_png from "./../../../images/footer-icons/png/telegram_hover_png.png";
import { useFetchSiteSectionQuery } from "../../../app/api/games.api";

export default function FooterMain() {
  const { t, i18n } = useTranslation();
  const phoneCard = [
    {
      title: t("for android"),
      image: android,
      link: "/android",
      type: "android",
    },
    {
      title: t("for iOS"),
      image: iphone,
      link: "/iphone",
      type: "ios",
    },
  ];
  const isMobile = useMediaQuery("(max-width: 960px)");
  const isTablet = useMediaQuery("(max-width: 1280px)");
  const { data } = useFetchSiteSectionQuery();
  const sections = useMemo(() => {
    if (data)
      return data.filter(
        (section) => section.language === i18n.resolvedLanguage
      );
    else return [];
    // eslint-disable-next-line
  }, [data]);

  const subdomain = window.location.hostname.split(".")[0];
  const halfWayIndex = Math.ceil(sections.length / 2);
  const first = sections.slice(0, halfWayIndex);
  const second = sections.slice(halfWayIndex);

  return (
    <div
      className={classNames(
        footer_style.container,
        footer_style.container_footer
      )}
    >
      <div className={css.main}>
        <div className={css.main_case}>
          <div className={css.logo_desktop}>
            <Link to={"/"} onClick={window.openSplash}>
              <img src={logo} alt="" />
            </Link>
            <div className={css.logo_desktop__line}></div>
          </div>
          <div className={css.main_social}>
            <NavLink
              to={{
                pathname:
                  subdomain === "kzt"
                    ? "https://t.me/garillacasino_kz"
                    : "https://t.me/GARILLA_CASINO_OFFICIAL",
              }}
              target={"_blank"}
              className={css.main_social__telegram}
            >
              <img src={telegram} className={css.telegram} alt="" />
              <img src={telegram_hover} className={css.telegram_hover} alt="" />
            </NavLink>
            <NavLink
              to={{ pathname: "https://www.instagram.com/garilla_inst" }}
              target={"_blank"}
              className={css.main_social__instagram}
            >
              <img src={instagram} className={css.instagram} alt="" />
              <img
                src={instagram_hover}
                className={css.instagram_hover}
                alt=""
              />
            </NavLink>
          </div>
          <div className={css.hidern_social}>
            <img src={vk_png} alt="" />
            <img src={vk_hover_png} alt="" />
            <img src={instagram_png} alt="" />
            <img src={instagram_hover_png} alt="" />
            <img src={telegram_png} alt="" />
            <img src={telegram_hover_png} alt="" />
          </div>
          <div className={css.menu}>
            <div className={css.menu_box}>
              <ul>
                {first.map((section) => {
                  if (section.slug !== "faq" && section.slug !== "about") {
                    return (
                      <li key={section.slug}>
                        <Link to={`/${section.slug}`}>{t(section.slug)}</Link>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>

              <ul>
                {second.map((section) => {
                  if (section.slug !== "faq" && section.slug !== "about") {
                    return (
                      <li key={section.slug}>
                        <Link to={`/${section.slug}`}>{t(section.slug)}</Link>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={css.logos}>
          <div className={css.logos__wrapper}>
            <span className={classNames(css.logos_item, css.adults)}>
              <img src={adults} alt="" />
            </span>
            <Link
              to={{ pathname: "https://itechlabs.com/" }}
              target={"_blank"}
              className={classNames(css.logos_item, css.iTechLabs)}
            >
              <img src={iTechLabs} alt="" />
            </Link>
            {subdomain === "kzt" ? (
              <div
                className={classNames(
                  css.logos_item,
                  css.curacao,
                  css.coming_soon
                )}
              >
                COMMING SOON
              </div>
            ) : (
              <Link
                className={classNames(css.logos_item, css.curacao)}
                target={"_blank"}
                to={{
                  pathname:
                    "https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbWR6VlROSGEzbHNNekZVT1hSR01IWnhkRkl6Ym1jOVBTSXNJblpoYkhWbElqb2lVRXgwUkZsSlNVTnRlVXBRT1VkRlozaFhVVFJYTWxWNFIwaFRPRzQ0T0VsNVpYSXpUa3RtZGpOVlJUMGlMQ0p0WVdNaU9pSTVZbVUyWVdZd01HWXpaV1ZtTjJFMk0yUTRNalF4TkdGaE5URmlOemxsWmpsaE56Z3haVEU0TlRjM05EVmhOalZsTmpCbE9EbGlOV1U1WXpOa1lUaGxJaXdpZEdGbklqb2lJbjA9",
                }}
              >
                <img src={gameCuracao} alt="" />
              </Link>
            )}
            <Link
              className={classNames(css.logos_item, css.gameCare)}
              to={{ pathname: "https://www.gamcare.org.uk/" }}
              target={"_blank"}
            >
              <img src={gameCare} alt="" />
            </Link>
            <Link
              className={classNames(css.logos_item, css.beGambleAware)}
              to={{ pathname: "https://www.begambleaware.org/" }}
              target={"_blank"}
            >
              <img src={beGambleAware} alt="" />
            </Link>
          </div>
        </div>
        <div className={css.application_menu}>
          {phoneCard.map((card, index) => (
            <div to={card.link} key={index}>
              <PhoneCard
                key={index}
                image={card.image}
                title={card.title}
                type={card.type}
                variant={!isMobile ? "desctop" : ""}
              />
            </div>
          ))}
        </div>
        <SupportBlock
          className={css.support}
          variant={!isMobile ? "desctop" : ""}
          sections={sections}
        />
      </div>
    </div>
  );
}
