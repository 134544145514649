import React from "react";
import css from "./bonus-header.module.css";
import { useTranslation } from "react-i18next";
import crystal from "./../../images/header/crystal.svg";
import crystal_hover from "./../../images/header/crystal_hover.svg";

export default function BonusHeader({ bonuses }) {
  const { t } = useTranslation();
  return (
    <div className={css.wraper}>
      <div className={css.block}>
        <div className={css.block_img}>
          <img src={crystal} className={css.img_svg} alt="" />
          <img src={crystal_hover} className={css.img_svg__hover} alt="" />
        </div>
        <span className={css.block_title}>{t("bonuses")}</span>
      </div>
      {bonuses && bonuses?.length !== 0 && (
        <div className={css.block_number}>{bonuses.length}</div>
      )}
    </div>
  );
}
