import PaymentsMethods from "../payment-methods/payment-methods";
import css from "../payment-default.module.css";
import { useTranslation } from "react-i18next";
import Form from "../form/deposit-form";
import Kauri from "../form/kauri";
import InnerPane from "../../../components/inner-pane/inner-pane";
import style from "../wallet.module.css";
import { useMediaQuery } from "beautiful-react-hooks";
import { useLocation } from "react-router-dom";
import { searchParams } from "../../../utils/search-params";
import {useGetPaymentMethodsQuery} from "../../../app/api/wallet.api";

export default function TopUp({ paymentMethod, setPaymentMethod }) {
  const { data } = useGetPaymentMethodsQuery();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const searchParameters = searchParams(location.search);

  if (!paymentMethod) {
    const content = (
      <div className={css.wrapper}>
        {<div className={css.title}>{t("Payment advice")}</div>}
        {data && (
          <PaymentsMethods
            setPaymentMethod={setPaymentMethod}
            methods={[...data]}
          />
        )}
      </div>
    );

    if (isMobile) {
      return content;
    }

    return (
      <InnerPane className={style.tab_content} paneClass={style.subpane}>
        {content}
      </InnerPane>
    );
  } else {
    if (paymentMethod.provider === "kauri") {
      return (
        <Kauri
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          defaultSelectedBonus={searchParameters.b}
        />
      );
    } else {
      return (
        <Form
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          defaultSelectedBonus={searchParameters.b}
        />
      );
    }
  }
}
