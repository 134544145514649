import css from "./pagination.module.css";
import classNames from "classnames";
import {searchParams} from "../../utils/search-params";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import {useHistory, useLocation} from "react-router-dom";

export default function Pagination({lastPage, setPage, className, page}) {
    const {t} = useTranslation();
    const Dots = () => <div className={classNames(css.page, css.dots)}>...</div>;
    const isMobile = useMediaQuery("(max-width: 620px)");
    const location = useLocation();
    const searchParameters = searchParams(location.search);
    const currentPage = parseInt(searchParameters.page ? searchParameters.page : 1);
    const history = useHistory();
    const goTo = targetPage => {
        history.push({
            pathname: location.pathname,
            search: '?page=' + targetPage
        });
        setPage(targetPage);
    };
    function Pages ({count, startFrom = 0}) {
        return (new Array(count)).fill("").map((i, idx) => (
            <div
                onClick={() => {
                     goTo(startFrom + idx + 1);
                }}
                key={`page-${startFrom + idx}`}
                className={classNames(css.page, {[css.current]: startFrom + idx + 1 === currentPage})}
            >
                {startFrom + idx + 1}
            </div>
        ))
    }

    useEffect(() => {
        if (parseInt(page) !== currentPage) {
            goTo(page)
        }
    }, [page]);

    return (
        <div className={classNames(css.pagination, className)}>
             <div className={css.pages}>
                {
                    currentPage > 1 && <div className={classNames(css.navigate_button, css.prev)} onClick={() => {
                         goTo(currentPage - 1);
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="13px" height="17px">
                            <path fillRule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="2px" strokeLinecap="round"
                                  strokeLinejoin="round" fill="none"
                                  d="M9.944,2.970 L2.039,8.004 L9.944,13.038 "/>
                        </svg>
                        {/* { !isMobile && <span>{t("Prev")}</span> } */}
                    </div>
                }
                {
                    lastPage && <div className={css.numeric_pages}>
                        {
                            lastPage < (isMobile ? 5 : 8) ?
                                <Pages count={lastPage}/>
                                : <>
                                    {
                                        currentPage <= (3) ?
                                            <>
                                                <Pages count={4}/>
                                                <Dots/>
                                                <Pages startFrom={lastPage - 1} count={1}/>
                                            </>
                                            : (
                                                currentPage >= (lastPage - 2) ?
                                                    <>
                                                        <Pages count={1}/>
                                                        <Dots/>
                                                        <Pages startFrom={lastPage - (4)} count={(4)}/>
                                                    </>
                                                    :
                                                    <>
                                                        { !isMobile && <Pages count={1}/> }
                                                        <Dots/>
                                                        <Pages count={3} startFrom={currentPage - 2}/>
                                                        <Dots/>
                                                        { !isMobile && <Pages startFrom={lastPage - 1} count={1}/> }
                                                    </>
                                            )
                                    }
                                </>
                        }
                    </div>

                }
                {
                    (!lastPage || currentPage < lastPage) &&
                    <div className={classNames(css.navigate_button, css.next)} onClick={() => {
                        goTo(currentPage + 1)
                    }}>
                        {/* { !isMobile && <span>{t("Next")}</span> } */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="13px" height="16px">
                            <path fillRule="evenodd"  stroke="rgb(255, 255, 255)" stroke-width="2px" strokeLinecap="round" strokeLinejoin="round" fill="none"
                                  d="M2.245,12.932 L9.928,7.715 L2.245,2.497 "/>
                        </svg>
                    </div>
                }
            </div>
        </div>
    );
}