import { useTranslation } from "react-i18next";
import css from "./free-money.module.css";

import topBannerRu from "../../images/free-money/ru/fm_main_banner_ru.png";
import topBannerEn from "../../images/free-money/en/fm_main_banner_en.png";
import topBannerKZ from "../../images/free-money/kz/fm_main_banner_kz.png";
import topBannerKg from "../../images/free-money/kg/fm_main_banner_kg.png";
import topBannerUz from "../../images/free-money/uz/fm_main_banner_uz.png";
import topBannerAz from "../../images/free-money/az/fm_main_banner_az.png";

import topBannerMobRu from "../../images/free-money/ru/fm_main_banner_mob_ru.png";
import topBannerMobEn from "../../images/free-money/en/fm_main_banner_mob_en.png";
import topBannerMobKZ from "../../images/free-money/kz/fm_main_banner_mob_kz.png";
import topBannerMobKg from "../../images/free-money/kg/fm_main_banner_mob_kg.png";
import topBannerMobUz from "../../images/free-money/uz/fm_main_banner_mob_uz.png";
import topBannerMobAz from "../../images/free-money/az/fm_main_banner_mob_az.png";

import { useMediaQuery } from "beautiful-react-hooks";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function FreeMoney() {
  const { i18n, t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const banners = [
    { lang: "ru", banner: isMobile ? topBannerMobRu : topBannerRu },
    { lang: "en", banner: isMobile ? topBannerMobEn : topBannerEn },
    { lang: "kz", banner: isMobile ? topBannerMobKZ : topBannerKZ },
    { lang: "kg", banner: isMobile ? topBannerMobKg : topBannerKg },
    { lang: "uz", banner: isMobile ? topBannerMobUz : topBannerUz },
    { lang: "az", banner: isMobile ? topBannerMobAz : topBannerAz },
  ];

  const subdomain = window.location.hostname.split(".")[0];
  const localCurrency = subdomain.length > 3 ? "ru" : subdomain;

  const telegramPhoneInfo = [
    { local: "ru", number: "150 ₽" },
    { local: "kzt", number: "750 ₸" },
    { local: "azn", number: "3 ₼" },
    { local: "kgs", number: "150 C" },
    { local: "uzs", number: "21 000 Cўм" },
  ];

  const getNumberTelegram = (local) => {
    return telegramPhoneInfo.find((item) => item.local === local)?.number;
  };

  const getBannerForLanguage = (lang) => {
    return banners.find((item) => item.lang === lang)?.banner;
  };

  return (
    <div className={css.fm_wrapper}>
      <div className={css.fm_border}></div>
      <div className={css.main_banner}>
        <img src={getBannerForLanguage(i18n.resolvedLanguage)} alt="" />
      </div>
      <div className={css.fm_group}>
        <div className={css.fm_tg}>
          <div className={css.fm_bonus}>
            {getNumberTelegram(localCurrency)}
            <div className={css.fm_bonus_txt}>{t("per subscription")}</div>
          </div>
          <div className={css.fm_condition}>
            <div className={css.fm_condition_title}>{t("Conditions")}</div>
            <ul>
              <li>{t("verification")}</li>
              <li>{t("Link your TG account to your profile")}</li>
              <li>
                {t("subscribe on")}&nbsp;
                <NavLink
                  to={{
                    pathname:
                      subdomain === "kzt"
                        ? "https://t.me/garillacasino_kz"
                        : "https://t.me/GARILLA_CASINO_OFFICIAL",
                  }}
                  target={"_blank"}
                  className={css.fm_condition_link}
                >
                  {t("group")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={css.fm_phone}>
          <div className={css.fm_bonus}>
            {getNumberTelegram(localCurrency)}
            <div className={css.fm_bonus_txt}>
              {t("for linking a phone number")}
            </div>
          </div>
          <div className={css.fm_condition}>
            <div className={css.fm_condition_title}>{t("Conditions")}</div>
            <ul>
              <li>{t("verification")}</li>
              <li>{t("Link your phone number")}</li>
              <li>
                <NavLink
                  to={"/profile"}
                  className={css.fm_condition_link}
                  target="_blank"
                >
                  {t("Confirm")}
                </NavLink>
                &nbsp;{t("number via TG")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
