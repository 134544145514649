import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../app/api/api.config";
import { normalizeNumericAmountToString } from "../../utils/validation";

export const walletApi = createApi({
  reducerPath: "WalletApi",
  baseQuery: baseConfig,
  tagTypes: ["PaymentsList"],
  endpoints: (builder) => ({
    getPaymentMethods: builder.query({
      query: () => "/payments/replenishment-methods",
      transformResponse: (response) => response.data,
    }),
    getPaymentDetails: builder.query({
      query: ({ slug }) => ({
        url: `/payments/replenishment-details/${slug}`,
      }),
    }),
    deposit: builder.mutation({
      query: (params) => {
        let body;

        if (["kauri"].includes(params.provider)) {
          body = params;
        } else if (["piastrix"].includes(params.provider)) {
          const { amount, provider, account } = params;
          body = {
            provider,
            amount: parseInt(amount),
            requisites: {
              account: account?.toString(),
            },
          };
        } else if (
          [
            "piastrix_sbp",
            "piastrix_sber",
            "piastrix_bank",
            "piastrix_sbp_secondary",
          ].includes(params.provider)
        ) {
          const { amount, provider, account } = params;
          body = {
            provider,
            amount: parseInt(amount),
            requisites: {
              account: account?.toString(),
            },
          };
        } else {
          const { amount, provider, phone } = params;
          body = {
            provider,
            amount: parseInt(amount),
            requisites: {
              phone: phone.toString(),
            },
          };
        }

        return {
          url: "/payments/replenishment",
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["PaymentsList"],
      transformResponse: (response) => response.data,
    }),
    checkLogin: builder.mutation({
      query: (login) => ({
        url: "/integration/transfer-out/check-login",
        body: {
          login,
        },
        method: "POST",
      }),
    }),
    sendMoney: builder.mutation({
      query: ({ login, amount }) => ({
        url: "/integration/transfer-out/send-money",
        body: {
          login,
          amount: normalizeNumericAmountToString(amount),
        },
        method: "POST",
      }),
      invalidatesTags: ["PaymentsList"],
    }),
    getWithdrawalPaymentMethods: builder.query({
      query: () => "/payments/payout-methods",
      transformResponse: (response) => response.data,
    }),
    payout: builder.mutation({
      query: ({ body }) => ({
        url: "/payments/payout",
        body,
        method: "POST",
      }),
      transformResponse: (response, query) => {
        if (query?.response?.status === 201) {
          return "ok";
        }
        return null;
      },
      invalidatesTags: ["PaymentsList"],
    }),
    fetchPaymentsList: builder.mutation({
      query: (filters) => {
        filters.per_page = 6;
        const params = [];

        for (let key in filters) {
          params.push(`${key}=${filters[key]}`);
        }

        const paramsStr = params.join("&");

        return {
          // url: `/payments/list${paramsStr.length > 0 ? `?${paramsStr}` : ""}`,
          url: `/payments/list`,
          body: filters,
          method: "POST",
        };
      },
      providesTags: ["PaymentsList"],
    }),
    fetchExchangeRates: builder.query({
      query: () => "/payments/exchange-rate-out",
    }),
    fetchExchangeRatesDeposit: builder.query({
      query: () => "/payments/exchange-rate-in",
    }),
    fetchExchangeRatesMainIn: builder.query({
      query: () => "/exchanger/in",
    }),
    fetchExchangeRatesMainOut: builder.query({
      query: (code) => `/exchanger/out`,
    }),
    cancelWithdrawal: builder.mutation({
      query: (id) => ({
        url: `/payments/payout-request/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PaymentsList"],
    }),
  }),
});

export const {
  useGetPaymentMethodsQuery,
  useGetPaymentDetailsQuery,
  useGetWithdrawalPaymentMethodsQuery,
  useDepositMutation,
  useCheckLoginMutation,
  useSendMoneyMutation,
  usePayoutMutation,
  useFetchPaymentsListMutation,
  useFetchExchangeRatesQuery,
  useFetchExchangeRatesDepositQuery,
  useFetchExchangeRatesMainInQuery,
  useFetchExchangeRatesMainOutQuery,
  useCancelWithdrawalMutation,
} = walletApi;
