export function searchParams (string) {
    const items = string.replace("?", "").split("&");
    const obj = {};

    items.forEach(str => {
        const couple = str.split("=");
        obj[couple[0]] = couple[1];
    });

    return obj;
}


/**
 * Функция добавляет или обновляет гет параметры в адресной строке
 * при появлении дублей, будет обновлять
 *
 * @param {string} url - строка, сождержащая урл, к которой добавим новые паметры. (может уже содержать параметры)
 * @param {string} key - имя параметра
 * @param {string} value - значение параметра
 * @returns {string} - обновленный урл
* */
export function addOrUpdateGetParameter(url, key, value) {
    let urlObj = new URL(url);
    let params = new URLSearchParams(urlObj.search);

    // Обновляем значение параметра или добавляем новый
    params.set(key, value);

    // Обновляем URL с новыми параметрами
    urlObj.search = params.toString();
    return urlObj.toString();
}


/**
 * Удаление гет параметров из строки
 *
 * @param {string} url -строка, сождержащая урл, из которой удалим параметры
 * @param {string[]} keys - Массив ключей, которые надо удалить
 * @param {boolean} withDomain - оставить домен или нет
 * @returns {string} - готовый урл
 * */
export  function removeGetParameters(url, keys, withDomain = true) {
    let urlObj = new URL(url);
    let params = new URLSearchParams(urlObj.search);

    // Удаляем указанные параметры, если они существуют
    keys.forEach(key => params.delete(key));

    // Обновляем URL с новыми параметрами
    urlObj.search = params.toString();

    // Возвращаем URL в зависимости от значения флага withDomain
    if (withDomain) {
        return urlObj.toString();
    } else {
        return urlObj.pathname + (params.toString() ? '?' + params.toString() : '');
    }
}