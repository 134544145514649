import React, {useMemo, useState} from "react";
import ru from 'react-phone-number-input/locale/ru'
import Input, {getCountryCallingCode, getCountries} from "react-phone-number-input/input";
import css from "./phone-input.module.css";
import classNames from "classnames";
import Select from "../../../../components/form3/select";
import PropTypes from "prop-types";
import CustomPhoneInput from "./phone-input";
import {useTranslation} from "react-i18next";
import {isValidPhoneNumber, isPossiblePhoneNumber} from "react-phone-number-input";
import {useMediaQuery} from "beautiful-react-hooks";
import input_css from "../../../../components/form3/style.module.css"


const PhoneInputNew = ({   formRegPhone, setErrorReg, setFormRegPhone, setFormReg, formReg }) => {
    const [value, setValue] = useState(formRegPhone || "" )
    const [country, setCountry] = useState('RU')
    const [isValid, setisValid] = useState(false)
    const { t, i18n } = useTranslation();
    const countryCode = getCountryCallingCode(country);
    const [maxLength, setMaxLength] = useState(20)


    const handleChange = (val) => {
        if (val) {
            if ( val.length < 13 || isPossiblePhoneNumber(val)) {
                const cleanedValue = val.replace(/[^0-9+]/g, "");
                setFormRegPhone(cleanedValue);
                setValue(cleanedValue);
                setMaxLength(20)
            } else {
                setMaxLength(val.replace(`+${getCountryCallingCode(country)}`, "").length)
            }
        } else {
            setFormRegPhone("");
        }

    };
    const handleCountryChange = (event) => {
        const selectedCountry = event.value;
        setCountry(selectedCountry);
        const countryCode = getCountryCallingCode(selectedCountry);

        if (value) {
            setValue(value.replace(/^\+[0-9]+/, ''));
            setFormRegPhone(`+${countryCode}${value.replace(/^\+[0-9]+/, '')}`);
        } else {
            setFormRegPhone(`+${countryCode}`);
        }
    };

    const handleBlur = (e) => {
        const cleanedValue = e.target.value.replace(/[^0-9+]/g, "");
        if (cleanedValue.length) {
            if (!/^(\+?7|8)[0-9]{10}$/.test(cleanedValue)) {
                setErrorReg({ phone: t("Invalid Russian phone number") });
            } else {
                setErrorReg({ phone: null });
            }
        }
        setisValid(!isValidPhoneNumber(formRegPhone))
        setFormReg({ ...formReg, phone: `+${countryCode }${cleanedValue}` });
    };

    return (
        <div className={css.container}>
            <CountrySelect
                labels={ru}
                value={country}
                onChange={setCountry}
                handleCountryChange={handleCountryChange}
                country={country}
            />
            <div
              className={classNames(
                input_css.wrapper,
                css.wrapper,
                {
                    [input_css.error]: isValid,
                    // [css.focused]: isFocused,
                }
              )}
            >
                <Input
                  defaultCountry="RU"
                  className={input_css.item}
                  value={value}
                  country={country}
                  onChange={handleChange}
                  maxlength={maxLength}
                  onBlur={handleBlur}
                  placeholder={t("phone")}
                />
            </div>
        </div>
    )
}

const CountrySelect = ({ value, onChange, labels, handleCountryChange, country, ...rest }) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const mobileSelect = !isMobile ? <div className={css.flag_container}> +{getCountryCallingCode(country)}</div> : <>+{getCountryCallingCode(country)}</>;
    const countries = getCountries().map((country) => ({
        label: "+" + getCountryCallingCode(country),
        labelSelected: mobileSelect,
        value: country,
    }));

    const countryValue = useMemo(
      () => countries.find((c) => c.value === value),
      [value]
    );

    return (
      <Select
        {...rest}
        isNotSearchable
        label={""}
        className={classNames(css.select__container)}
        options={countries}
        value={countryValue}
        onChange={handleCountryChange}
        placeholder={<> +{getCountryCallingCode("RU")}</>}
        maxHeight={300}
      />
    )
}

CustomPhoneInput.propTypes = {
    t: PropTypes.func.isRequired,
    errorsReg: PropTypes.object.isRequired,
    formRegPhone: PropTypes.string.isRequired,
    setErrorReg: PropTypes.func.isRequired,
    setFormRegPhone: PropTypes.func.isRequired,
    setFormReg: PropTypes.func.isRequired,
    formReg: PropTypes.object.isRequired
};

export default PhoneInputNew;
