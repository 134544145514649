import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "./api.config";
import i18n from "i18next";

export const gamesApi = createApi({
  reducerPath: "GamesApi",
  baseQuery: baseConfig,
  tagTypes: ["LAST_PLAYED", "FAVORITES", "GAMES_LIST"],
  endpoints: (builder) => ({
    getProviders: builder.query({
      query: () => "game/provider",
    }),
    getProducers: builder.query({
      query: () => "game/producer",
    }),
    getGames: builder.query({
      query: ({
        provider,
        producer,
        device,
        page,
        per_page,
        title,
        category,
        playOnWager,
      }) => {
        let filters = "";

        if (title) {
          filters += `&title=${title}`;
        }

        if (category) {
          filters += `&section=${category}`;
        }

        if (provider) {
          filters += `&provider=${provider}`;
        }

        if (per_page) {
          filters += `&limit=${per_page}`;
        }

        if (producer) {
          filters += `&producer=${producer}`;
        }
        const url = playOnWager
          ? `/game/list?device=${device}&page=${page}&can_play_on_wager=1${filters}`
          : `/game/list?device=${device}&page=${page}${filters}`;

        return url;
      },
      providesTags: ["GAMES_LIST"],
    }),
    getAvailableForFreespin: builder.query({
      query: () => "/game/available-for-freespin",
    }),
    getGamesWithSections: builder.query({
      query: ({
        provider,
        producer,
        device,
        page,
        per_page,
        title,
        sections,
      }) => {
        let filters = "";

        if (title) {
          filters += `&title=${title}`;
        }

        if (sections) {
          filters += `&sections=${sections}`;
        }

        if (provider) {
          filters += `&provider=${provider}`;
        }

        if (per_page) {
          filters += `&limit=${per_page}`;
        }

        if (producer) {
          filters += `&producer=${producer}`;
        }
        return (
          `/game/list-limit-section?device=${device}&page=${page}` + filters
        );
      },
    }),
    getGamesMainSections: builder.query({
      query: ({
        provider,
        producer,
        device,
        page,
        per_page,
        title,
        sections,
        playOnWager,
      }) => {
        let filters = "";

        if (title) {
          filters += `&title=${title}`;
        }

        if (sections) {
          filters += `&sections=${sections}`;
        }

        if (provider) {
          filters += `&provider=${provider}`;
        }

        if (page) {
          filters += `&page=${page}`;
        }

        if (per_page) {
          filters += `&limit=${per_page}`;
        }

        if (producer) {
          filters += `&producer=${producer}`;
        }
        const url = playOnWager
          ? `/game/list-limit-section-main?device=${device}&can_play_on_wager=1${filters}`
          : `/game/list-limit-section-main?device=${device}${filters}`;

        return url;
      },
    }),
    getGamesMainThemes: builder.query({
      query: ({ themes, playOnWager }) => {
        const url = playOnWager
          ? `game/list-limit-theme?themes=${themes}&can_play_on_wager=1`
          : `game/list-limit-theme?themes=${themes}`;
        return url;
      },
    }),
    getMedia: builder.query({
      query: (position = "main_top") => `/media/banner?position=${position}`,
    }),
    gameSession: builder.mutation({
      query: ({ body, demo = false }) => {
        const advanced = {};
        if (body.gameId.includes("playtech")) {
          advanced.depositUrl = window.location.origin + "/wallet";
          advanced.deposit_url = window.location.origin + "/wallet";
        }

        return {
          url: `/game/${demo ? "demo" : "session"}`,
          method: "POST",
          body: {
            ...body,
            ...advanced,
          },
        };
      },
      invalidatesTags: ["LAST_PLAYED"],
    }),
    fetchSections: builder.query({
      query: () => "/list/sections",
    }),
    fetchThemes: builder.query({
      query: () => "/list/game-themes",
    }),
    fetchTheme: builder.query({
      query: ({ title, page, limit = 24, playOnWager }) => {
        const url = playOnWager
          ? `game/list/theme/${title}?page=${page}&can_play_on_wager=1&limit=${limit}`
          : `game/list/theme/${title}?page=${page}&limit=${limit}`;
        return url;
      },
    }),
    fetchLastPlayed: builder.query({
      query: (params) => {
        const paramsArray = [];

        for (let key in params) {
          paramsArray.push(`${key}=${params[key]}`);
        }

        return `/game/last-played${
          paramsArray.length > 0 ? "?" : ""
        }${paramsArray.join("&")}`;
      },
      providesTags: ["LAST_PLAYED"],
    }),
    favorite: builder.mutation({
      query: ({ gameId }) => ({
        url: `/game/favorite`,
        body: { gameId },
        method: "POST",
      }),
      invalidatesTags: ["FAVORITES"],
    }),
    fetchFavorites: builder.query({
      query: ({ per_page, device, page }) => {
        let filters = "";

        if (per_page) {
          filters += `&limit=${per_page}`;
        }

        return `/game/favorite?device=${device}&page=${page}` + filters;
      },
      providesTags: ["FAVORITES"],
    }),
    fetchSiteSection: builder.query({
      query: () => `/site/section?limit=100`,
      transformResponse: (response) => response.data,
    }),
    fetchSiteSectionBySlug: builder.query({
      query: ({ slug, lang }) => ({
        url: `/site/section/get?slug=${slug}`,
        headers: { "X-User-Locale": lang },
      }),
    }),
    lastWins: builder.mutation({
      query: ({ size }) => ({
        url: `/game-action/last-wins?count=${size}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useGetProducersQuery,
  useGetGamesQuery,
  useGetGamesWithSectionsQuery,
  useGetGamesMainSectionsQuery,
  useGetGamesMainThemesQuery,
  useGetMediaQuery,
  useGameSessionMutation,
  useFetchSectionsQuery,
  useFetchThemesQuery,
  useFetchThemeQuery,
  useFetchLastPlayedQuery,
  useFavoriteMutation,
  useFetchSiteSectionQuery,
  useFetchSiteSectionBySlugQuery,
  useFetchFavoritesQuery,
  useGetAvailableForFreespinQuery,
  useLastWinsMutation,
} = gamesApi;
