import css from "./style.module.css";
import Button from "../../../components/button/button";
import { useTranslation } from "react-i18next";
import InnerPane from "../../../components/inner-pane/inner-pane";
import Input from "../../../components/form3/input";
import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAuth } from "../../../services/auth";
import arrowLeft from "../../../images/arrow-left.svg";
import Loader from "../../../components/loader/Loader";
import { toCurrencyString, validateEmail } from "../../../utils/validation";
import CancelBonus from "../../my-bonus/cancel-bonus/cancel-bonus";
import MaskInput from "../../../components/form2/mask-input";
import { useMediaQuery } from "beautiful-react-hooks";
import TimerComponent from "../../my-bonus/available-bonuses/timer/available-at-timer";
import { loadBaseUrl, PROTOCOL } from "../../../app/api/api.config";
import hasNoBonus from "../../../images/bonuses/has-no-bonus.svg";
import DescriptionBonus from "../../my-bonus/description-bonus/description-bonus";
import { Swiper, SwiperSlide } from "swiper/react";
import noBonusImg from "./../../../images/wallet/no_bonus.svg";
import SlideControlButton from "../../my-bonus/available-bonuses/slide-control/control";
import { useDepositMutation } from "../../../app/api/wallet.api";
import {
  useGetActiveBonusQuery,
  useGetBonusSlotQuery,
  useTakeBonusMutation,
} from "../../../app/api/bonus.api";

export default function DepositForm({
  paymentMethod,
  setPaymentMethod,
  defaultSelectedBonus,
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const emailEnterForDep =
    paymentMethod.slug === "piastrix_sbp" ||
    paymentMethod.slug === "piastrix_sber" ||
    paymentMethod.slug === "piastrix_bank";
  const getDefaultNumber = () => {
    if (paymentMethod.slug === "piastrix") {
      return "";
    }
    if (emailEnterForDep) {
      return auth?.user?.email ? auth.user.email : "";
    }
    return auth?.user?.phone
      ? auth.user.phone.substring(0, auth.user.phone)
      : "";
  };
  const currency = auth?.user?.currency?.code;

  const defaultBonus = {
    id: 1,
    title: t("no bonus"),
    image: [noBonusImg],
  };
  const [number, setNumber] = useState(getDefaultNumber());
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [amount, setAmount] = useState("");
  const inputNumber = useRef();
  const [replenish, { data: payUrl, isLoading }] = useDepositMutation();
  const { data: bonusSlot, isLoading: bonusLoading } = useGetBonusSlotQuery();
  const { data } = useGetBonusSlotQuery();
  const {
    data: activeBonus,
    isLoading: fetchingActiveBonus,
    error: errorActiveBonus,
  } = useGetActiveBonusQuery();
  const [acceptedBonus, setAccepted] = useState(activeBonus);
  const [activeSwiperIndex, setActiveSwiperIndex] = useState();
  const [selectedBonus, selectBonus] = useState(Number(defaultSelectedBonus));
  const newDataArray = Array.isArray(data)
    ? [defaultBonus, ...data]
    : [defaultBonus];
  const bonus = newDataArray?.find((item) => item.id === selectedBonus);
  const [timeBonusAvalailableAt, setTimeBonusAvalailableAt] = useState(null);
  const [notDepBonus, setNotDepBonus] = useState(false);
  const [
    activateBonus,
    { data: bonusActivateData, isLoading: bonusActivating },
  ] = useTakeBonusMutation();
  const [showConfirm, setShowConfirm] = useState();
  const selectedBonusObj = useMemo(() => {
    return bonusSlot?.find((item) => item.id == selectedBonus);
  }, [selectedBonus, bonusSlot]);
  const host = loadBaseUrl().split("/")[0];
  const isMobile = useMediaQuery("(max-width: 960px)");
  const isSmallMobile = useMediaQuery("(min-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1280px)");
  const itemsPerPage = isTablet ? 2 : 4;

  const slides = useMemo(() => {
    if (!newDataArray) {
      return [];
    }
    const t = newDataArray;

    const res = [];
    const int = Math.trunc(t.length / itemsPerPage);
    const rest = t.length % itemsPerPage;

    for (let i = 0; i < int; i++) {
      res[i] = t.slice(i * itemsPerPage, itemsPerPage * (i + 1));
    }
    if (rest > 0) {
      res.push(t.slice(-rest));
    }
    for (let i = 0; i < itemsPerPage; i++) {
      if (!res[res.length - 1][i]) {
        res[res.length - 1][i] = {};
      }
    }

    return res;
  }, [data, itemsPerPage]);

  const onPayClick = () => {
    if (
      selectedBonus &&
      acceptedBonus &&
      acceptedBonus.id != selectedBonus &&
      selectedBonus !== 1
    ) {
      //ЕСли есть активный бонус и юзер выбрал другой, то вывести препреждение, это не Без бонуса, после подверждения бонус будет деактивирован
      setShowConfirm(true);
    } else {
      action();
    }
  };

  const action = () => {
    if (
      selectedBonus &&
      acceptedBonus?.id != selectedBonus &&
      selectedBonus !== 1
    ) {
      //Если бонус выбран, но это не тот бонус, который был выбран ранее, это не Без бонуса, нужно активировать новый бонус
      activateBonus({ slotId: selectedBonus });
    } else {
      //ЕСли нет выбранного или выбран тот уже уже активный, то просто пополняем
      sendDeposit();
    }
  };
  const sendDeposit = () => {
    switch (paymentMethod.slug) {
      case "telegram_pay":
        window.location.href = "https://t.me/GarillaCasino";
        break;
      case "piastrix":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          account: number,
        });
        break;
      case "piastrix_sbp":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          account: number,
        });
        break;
      case "piastrix_sbp_secondary":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          account: number,
        });
        break;
      case "piastrix_sber":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          account: number,
        });
        break;
      case "piastrix_bank":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          account: number,
        });
        break;
      case "blackRabbit_p2p":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          phone: parseInt(number),
        });
        break;
      case "paycos_p2p_106":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          phone: parseInt(number),
        });
        break;
      case "wallet_expert_p2p":
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          phone: parseInt(number),
        });
        break;
      default:
        replenish({
          amount: amount.replace(/\s/g, ""),
          provider: paymentMethod.provider,
          phone: parseInt(number),
        });
        break;
    }
  };

  const onSuccessCancel = () => {
    //Если прошла успешная отмена активного бонуса в момент оплаты при выборе другого бонуса
    action();
  };

  useEffect(() => {
    if (selectedBonusObj?.available_at && !selectedBonusObj?.available) {
      setTimeBonusAvalailableAt(selectedBonusObj.available_at);
    } else if (timeBonusAvalailableAt) {
      setTimeBonusAvalailableAt(null);
    }
  }, [selectedBonus]);

  useEffect(() => {
    if (activeBonus) {
      setAccepted(activeBonus);
    }
    if (errorActiveBonus) {
      setAccepted(null);
    }
  }, [errorActiveBonus, activeBonus]);

  useEffect(() => {
    if (bonusActivateData) {
      window.localStorage.setItem("show_bonus_info", true);
      sendDeposit();
    }
    // eslint-disable-next-line
  }, [bonusActivateData]);

  useEffect(() => {
    if (payUrl) {
      //Если была успешно отправлена форма оплаты
      window.location.href = payUrl;
    }
  }, [payUrl]);

  useEffect(() => {
    if (newDataArray && !selectedBonus) {
      //Если при загрузке страницы были получены бонусы и при этом еще не был получен/выбран активный бонус, то выберем первый
      selectBonus(newDataArray[0]?.id);
    }
  }, [selectedBonus, newDataArray]);

  useEffect(() => {
    if (acceptedBonus && acceptedBonus.id) {
      //Если есть активынй бонус
      selectBonus(acceptedBonus.id);
    } else if (!acceptedBonus && selectedBonus) {
      //если с бека пришло что активного бонуса нет, а в стейте он есть, значит надо очисить стейт
      // selectBonus(null);
    }
    // eslint-disable-next-line
  }, [acceptedBonus]);

  useEffect(() => {
    const amountNumber = Number(amount.replace(/\s/g, ""));
    const maxAmount = selectedBonusObj?.deposit_max_amount;
    const minAmount =
      selectedBonusObj?.deposit_min_amount?.toStringWithSubUnits(
        auth.user.currency?.subunits
      );

    const condition =
      (maxAmount && amountNumber > maxAmount) ||
      (minAmount && amountNumber < minAmount);

    setNotDepBonus(condition);
  }, [selectedBonusObj, auth.user.currency?.subunits, amount]);

  const body = (
    <>
      <div className={css.wrapper}>
        <div className={css.detail}>
          <div className={css.detail_backward__wraper}>
            <div
              className={css.backward}
              onClick={() => setPaymentMethod(null)}
            >
              <img
                src={arrowLeft}
                className={css.back}
                onClick={() => setPaymentMethod(null)}
                alt=""
              />
            </div>
            <div className={css.payment_icon}>
              <img src={paymentMethod.icon} className={css.icon} alt="" />
            </div>
          </div>
          {paymentMethod.slug === "telegram_pay" ? (
            <div className={css.telegram_info}>
              <p>{t("To fund your account:")}</p>
              <p>
                {t(
                  "1. Select from the carousel on the right the bonus available for you*."
                )}
              </p>
              <p>
                {t(
                  "2. Click the Recharge button. You will be taken to the telegram channel “Deposit GC”."
                )}
              </p>
              <p>
                {t(
                  "3. In the pinned message of the channel you will find the details for which you need to make a transfer of funds."
                )}
              </p>
              <p>
                {t(
                  "* if you have chosen a bonus, the transfer amount must comply with the conditions for its activation."
                )}
              </p>
              <p>
                {t(
                  "4. Follow further instructions from the pinned message to confirm the operation."
                )}
              </p>
            </div>
          ) : (
            <div className={css.requisites}>
              <div className={css.requisites_margin}>
                <div className={css.inputs}>
                  <Input
                    className={css.number}
                    placeholder={t(
                      paymentMethod.slug === "piastrix"
                        ? "Account name (piastrix)"
                        : emailEnterForDep
                          ? "Email"
                          : "Phone number"
                    )}
                    value={number}
                    onChange={(val) => {
                      if (paymentMethod.slug === "piastrix") {
                        setNumber(val);
                        setPhoneNumberError(false);
                      } else if (emailEnterForDep) {
                        setNumber(val);
                        if (validateEmail(val)) {
                          setPhoneNumberError(false);
                        } else {
                          setPhoneNumberError(true);
                        }
                      } else {
                        const cleanedValue = val.replace(/[^0-9+]/g, "");
                        if (cleanedValue.length === 0 || cleanedValue === "+") {
                          setNumber("+7");
                        } else if (/^\+7/.test(cleanedValue)) {
                          setNumber(cleanedValue.substring(0, 12));
                        } else {
                          setNumber("+7" + cleanedValue.substring(0, 11));
                        }
                        if (cleanedValue.length === 10) {
                          setPhoneNumberError(false);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      if (
                        paymentMethod.slug === "piastrix" &&
                        e.target.value.length < 2
                      ) {
                        setPhoneNumberError(true);
                      } else if (emailEnterForDep) {
                        const value = e.target.value.replace(/[\n\s\t]+/g, "");
                        if (value.length) {
                          if (validateEmail(value)) {
                            setPhoneNumberError(false);
                          } else {
                            setPhoneNumberError(true);
                          }
                        }
                      } else if (
                        paymentMethod.slug !== "piastrix" &&
                        !emailEnterForDep
                      ) {
                        const value = e.target.value.replace(/[\n\s\t]+/g, "");
                        if (value.length) {
                          if (!/^(\+?7|8)[0-9]{10}$/.test(value)) {
                            setPhoneNumberError(true);
                          } else {
                            setPhoneNumberError(false);
                          }
                        }
                        if (value.length !== e.target.value.length) {
                          setNumber(value);
                        }
                      }
                    }}
                    error={phoneNumberError}
                    refLink={inputNumber}
                  />
                </div>
                <div className={css.amount}>
                  <MaskInput
                    onChange={setAmount}
                    placeholder={t("deposit amount")}
                    icon={auth.user.currency?.sign}
                    scale={auth.user.currency?.subunits}
                  />
                </div>
              </div>
            </div>
          )}
          {paymentMethod.slug !== "telegram_pay" && (
            <div className={css.deposit_limits}>
              {paymentMethod.min_limit && (
                <div>
                  {t("Min amount deposit")} -{" "}
                  {toCurrencyString(
                    paymentMethod.min_limit.toStringWithSubUnits(
                      auth.user.currency?.subunits
                    )
                  )}{" "}
                  {auth.user.currency?.sign}
                </div>
              )}
              {paymentMethod.max_limit && (
                <div>
                  {t("Max amount deposit")} -{" "}
                  {toCurrencyString(
                    paymentMethod.max_limit.toStringWithSubUnits(
                      auth.user.currency?.subunits
                    )
                  )}{" "}
                  {auth.user.currency?.sign}
                </div>
              )}
            </div>
          )}
          <div className={css.processDep_info}>
            {t("to")}
            <span className={css.processDep_info__color}>
              {t("improve the process")}
            </span>
            {t(
              "of obtaining details, please create requests to top up your balance with"
            )}
            <span className={css.processDep_info__color}>
              {t("NOT rounded amounts")}
            </span>
            {t("for example")}
          </div>
          <TopUpButton
            className={css.desktop}
            timeBonusAvalailableAt={timeBonusAvalailableAt}
            onPayClick={onPayClick}
            bonusActivating={bonusActivating}
            notDepBonus={notDepBonus}
            amount={amount}
            action={action}
            setShowConfirm={setShowConfirm}
            acceptedBonus={acceptedBonus}
            isLoading={isLoading || bonusActivating}
            paymentMethod={paymentMethod}
            selectedBonus={selectedBonus}
            number={number}
            phoneNumberError={phoneNumberError}
          />
          {isTablet &&
          amount &&
          selectedBonusObj &&
          ((selectedBonusObj?.deposit_max_amount &&
            amount.replace(/\s/g, "") > selectedBonusObj?.deposit_max_amount) ||
            (selectedBonusObj?.deposit_min_amount &&
              amount.replace(/\s/g, "") <
                selectedBonusObj?.deposit_min_amount.toStringWithSubUnits(
                  auth.user.currency?.subunits
                ))) ? (
            <div className={css.bonus__notice}>
              {t("Bonus conditions not met")}
            </div>
          ) : (
            <div className={css.bonus__notice}>
              <br />
            </div>
          )}
        </div>
        <div className={css.detail_blok__bonus}>
          <div className={classNames(css.alt, css.card_deposit, css.bonus)}>
            {(bonusLoading || fetchingActiveBonus) && <Loader />}
            {(bonusLoading || fetchingActiveBonus) && !acceptedBonus ? null : (
              <>
                <div className={css.available_block}>
                  <Swiper
                    onSlideChange={(e) => setActiveSwiperIndex(e.activeIndex)}
                  >
                    {slides.map((slide, slideIndex) => (
                      <SwiperSlide key={slideIndex}>
                        <div className={css.cards_wraper}>
                          {slide?.map((item, index) =>
                            item.id ? (
                              <img
                                className={classNames(css.bonus_card, {
                                  [css.bonus_card__active]:
                                    selectedBonus === item.id,
                                })}
                                key={item.id}
                                onClick={() => {
                                  selectBonus(item.id);
                                }}
                                src={
                                  item.id === 1
                                    ? noBonusImg
                                    : `${PROTOCOL}${host}/uploads/${item.image}`
                                }
                                alt=""
                              />
                            ) : (
                              <div
                                className={css.no_bonus}
                                key={`no-bonus-${index}`}
                              >
                                <img src={hasNoBonus} alt="" />
                                <span className={css.no_bonus__title}>
                                  {t("has no bonus")}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                    {newDataArray?.length > itemsPerPage && (
                      <div className={css.pagination}>
                        {slides.map((_, index) => (
                          <SlideControlButton
                            key={index}
                            i={index}
                            isActive={index === activeSwiperIndex}
                          />
                        ))}
                      </div>
                    )}
                  </Swiper>
                </div>
                <span className={css.available_block__text}>
                  {t("select bonus")}
                </span>
                <div className={css.divider} />
              </>
            )}
            {timeBonusAvalailableAt ? (
              <TimerComponent dateString={timeBonusAvalailableAt} />
            ) : (
              <div className={css.bonus__notice}>
                {!isTablet &&
                amount &&
                selectedBonusObj &&
                ((selectedBonusObj?.deposit_max_amount &&
                  amount.replace(/\s/g, "") >
                    selectedBonusObj?.deposit_max_amount) ||
                  (selectedBonusObj?.deposit_min_amount &&
                    amount.replace(/\s/g, "") <
                      selectedBonusObj?.deposit_min_amount.toStringWithSubUnits(
                        auth.user.currency?.subunits
                      ))) ? (
                  <>
                    {t("Bonus conditions not met")}
                    {selectedBonus &&
                    acceptedBonus &&
                    acceptedBonus.id !== selectedBonus ? (
                      <br />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {selectedBonus !== 1 &&
                selectedBonus &&
                acceptedBonus &&
                acceptedBonus.id !== selectedBonus ? (
                  <span className={css.text_active__bonus}>
                    {t("You already have an active bonus")}&nbsp;
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(css.description_block, css.dep_description, {
              [css.description_block_notBonus]: selectedBonus === 1,
            })}
          >
            {selectedBonus === 1 ? (
              <div className={css.description_block__title}>
                {t("no bonus")}
              </div>
            ) : newDataArray ? (
              <DescriptionBonus bonus={bonus} currency={currency} />
            ) : (
              <Loader />
            )}
            {!isSmallMobile && (
              <TopUpButton
                className={css.mobile}
                timeBonusAvalailableAt={timeBonusAvalailableAt}
                onPayClick={onPayClick}
                bonusActivating={bonusActivating}
                notDepBonus={notDepBonus}
                amount={amount}
                action={action}
                setShowConfirm={setShowConfirm}
                acceptedBonus={acceptedBonus}
                isLoading={isLoading || bonusActivating}
                paymentMethod={paymentMethod}
                selectedBonus={selectedBonus}
                number={number}
                phoneNumberError={phoneNumberError}
              />
            )}
          </div>
        </div>
      </div>
      <CancelBonus
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        onCancelSuccess={onSuccessCancel}
      />
    </>
  );

  if (isMobile) {
    return body;
  }

  return (
    <InnerPane paneClass={css.inner_pane} className={css.inner_pane_container}>
      {body}
    </InnerPane>
  );
}
function TopUpButton({
  paymentMethod,
  isLoading,
  bonusActivating,
  selectedBonus,
  acceptedBonus,
  setShowConfirm,
  action = () => {},
  timeBonusAvalailableAt,
  notDepBonus,
  onPayClick,
  amount,
  number,
  className = "",
  phoneNumberError,
}) {
  const { t } = useTranslation();
  const [errorInput, setErrorInput] = useState(phoneNumberError);

  useEffect(() => {
    setErrorInput(phoneNumberError);
  }, [phoneNumberError]);

  return paymentMethod.slug === "telegram_pay" ? (
    <Button
      isLoading={isLoading}
      className={classNames(css.bonus__button, css.bonus__button_telegram)}
      variant="violet"
      onClick={() => {
        if (
          selectedBonus &&
          acceptedBonus &&
          acceptedBonus.id != selectedBonus
        ) {
          //ЕСли есть активный бонус и юзер выбрал другой, то вывести препреждение, после подверждения бонус будет деактивирован
          setShowConfirm(true);
        } else {
          action();
        }
      }}
      disabled={timeBonusAvalailableAt}
    >
      {t("top up")}
    </Button>
  ) : (
    <Button
      isLoading={isLoading || bonusActivating}
      className={classNames(
        css.bonus__button,
        {
          [css.button_top_notBonus]: selectedBonus === 1,
        },
        className
      )}
      onClick={onPayClick}
      variant="violet"
      disabled={
        errorInput ||
        !amount ||
        amount === "0" ||
        (amount &&
          paymentMethod.min_limit &&
          parseFloat(amount) < paymentMethod.min_limit / 100) ||
        (amount &&
          paymentMethod.max_limit &&
          parseFloat(amount) > paymentMethod.max_limit / 100) ||
        !number ||
        number === "0" ||
        (number.length < 10 && paymentMethod.slug !== "piastrix") ||
        timeBonusAvalailableAt ||
        notDepBonus
      }
    >
      {t("top up")}
    </Button>
  );
}
