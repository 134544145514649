import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import css from './phone-input.module.css';
import ru from 'react-phone-number-input/locale/ru';
import Input from 'react-phone-number-input/input';
import Select from "../../../../components/form3/select";

const CustomPhoneInput = ({ t, errorsReg, formRegPhone, setErrorReg, setFormRegPhone, setFormReg, formReg }) => {
    const [value, setValue] = useState(formRegPhone || "+7");
    const [country, setCountry] = useState("RU");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setFormRegPhone("+7");
    }, []);

    const handleBlur = (e) => {
        const cleanedValue = e.target.value.replace(/[\n\s\t]+/g, "");
        if (cleanedValue.length) {
            if (!/^(\+?7|8)[0-9]{10}$/.test(cleanedValue)) {
                setErrorReg({ phone: t("Invalid Russian phone number") });
            } else {
                setErrorReg({ phone: null });
            }
        }
        setFormReg({ ...formReg, phone: cleanedValue });
    };

    const handleChange = (val) => {
        if (val) {
            const cleanedValue = val.replace(/[^0-9+]/g, "");
            if (cleanedValue.length === 0 || cleanedValue === "+") {
                setFormRegPhone("+7");
            } else if (/^\+7/.test(cleanedValue)) {
                setFormRegPhone(cleanedValue.substring(0, 12));
            } else {
                setFormRegPhone("+7" + cleanedValue.substring(0, 11));
            }
            setValue(cleanedValue);
            if (cleanedValue.length === 10) {
                setErrorReg({ phone: null });
            }
        } else {
            setFormRegPhone("");
        }
    };

    const handleCountryChange = (event) => {
        setIsOpen(false);
        const selectedCountry = event.target.value;
        setCountry(selectedCountry);
        const countryCode = getCountryCallingCode(selectedCountry);
        if (value) {
            setValue(`+${countryCode}${value.replace(/^\+[0-9]+/, '')}`);
            setFormRegPhone(`+${countryCode}${value.replace(/^\+[0-9]+/, '')}`);
        } else {
            setValue(`+${countryCode}`);
            setFormRegPhone(`+${countryCode}`);
        }
    };

    const handleOpenSelect = () => {
        setIsOpen(true);
    };

    const handleSelectBlur = () => {
        setIsOpen(false);
    };
    const countries = getCountries().map((country) => ({
            label: `${ru[country]} (+${getCountryCallingCode(country)})`,
            value: country,
        }));

    return (
        <div className={css.input_container}>
            {/*<div className={classNames(css.select_container, css.wrapper)}>*/}
            {/*    <select*/}
            {/*        className={classNames(css.item_select, css.item)}*/}
            {/*        value={country}*/}
            {/*        onChange={handleCountryChange}*/}
            {/*        onClick={handleOpenSelect}*/}
            {/*        onBlur={handleSelectBlur}*/}
            {/*    >*/}
            {/*        <option value="">{ru['ZZ']}</option>*/}
            {/*        {getCountries().map((country) => (*/}
            {/*            <option key={country} value={country}>*/}
            {/*                {isOpen ? `${ru[country]} (+${getCountryCallingCode(country)})` : `+${getCountryCallingCode(country)}`}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*    <div className={css.arrow} />*/}
            {/*</div>*/}
            <div className={classNames(css.select_container, css.wrapper)}>
            <Select
                className={classNames(css.item_select, css.item)}
                options={countries}
                value={value}
                onChange={handleCountryChange}
                // onBlur={handleSelectBlur}
                placeholder={ru['ZZ']}
            />
            </div>
            <Input
                className={classNames(css.item, css.item_input)}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("write down your phone number")}
                error={errorsReg.phone ? t("Invalid Russian phone number") : undefined}
                maxLength={16}
            />
        </div>
    );
};

CustomPhoneInput.propTypes = {
    t: PropTypes.func.isRequired,
    errorsReg: PropTypes.object.isRequired,
    formRegPhone: PropTypes.string.isRequired,
    setErrorReg: PropTypes.func.isRequired,
    setFormRegPhone: PropTypes.func.isRequired,
    setFormReg: PropTypes.func.isRequired,
    formReg: PropTypes.object.isRequired
};

export default CustomPhoneInput;
