import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import i18n from "i18next";
import { setErr500 } from "../app.slice";

export const PROTOCOL = "https://";

export const loadBaseUrl = () => {

  if (
    process.env &&
    process.env.REACT_APP_BACKEND_API
  ) {
    return process.env.REACT_APP_BACKEND_API;
  } else {
    return `${window.location.host}/api/v1`;
  }
};

export const loadAuthUrl = () => {
  if (process.env.REACT_APP_DIRECT_AUTH) {
    return loadBaseUrl();
  }

  if (
    process.env &&
    process.env.REACT_APP_AUTH_URL &&
    process.env.NODE_ENV === "development"
  ) {
    return process.env.REACT_APP_AUTH_URL;
  } else {
    const { host } = window.location;
    const splitted = host.split(".");
    if (splitted.length > 1) {
      return `auth.${splitted[splitted.length - 2]}.${
        splitted[splitted.length - 1]
      }`;
    } else {
      return `auth.${window.location.host}`;
    }
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${PROTOCOL}${loadBaseUrl()}`,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("access_token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    const lang = i18n.resolvedLanguage.includes("-")
      ? i18n.resolvedLanguage.split("-")[0]?.toLowerCase()
      : i18n.resolvedLanguage.toLowerCase();
    headers.set("X-User-Locale", lang ? lang : "ru");

    return headers;
  },
});
// eslint-disable-next-line
export default async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // eslint-disable-next-line
  const logout = () => {};

  const errorCodes = [422, 417, 405, 400];

  if (result.error && errorCodes.includes(result.error.status)) {
    if (result.error.data?.errors) {
      const errors = result.error.data.errors;
      Object.keys(errors).forEach((errKey) => {
        const errorMessage = errors[errKey].join(", ");
        const notify = () =>
          toast(
            i18n?.exists(`errors.${errorMessage}`)
              ? i18n?.t(`errors.${errorMessage}`)
              : errorMessage,
            {
              theme: "dark",
              type: "error",
            }
          );
        notify();
      });
    } else {
      const errorMessage = result?.error?.data?.message;
      const notify = () =>
        toast(
          i18n?.exists(`errors.${errorMessage}`)
            ? i18n?.t(`errors.${errorMessage}`)
            : errorMessage,
          {
            theme: "dark",
            type: "error",
          }
        );
      notify();
    }
  }
  if (result.error && result.error.status === 500) {
    api.dispatch(setErr500(true));
  }

  // if (result.error && (result.error.status === 401 ) && api.endpoint !== "login" && !(window.location.pathname === '/registration') && (window.location.pathname === '/')) {
  //     // console.log(result, args, api, extraOptions)
  //     localStorage.removeItem("access_token");
  //     window.openSplash();
  //     api.dispatch(setRegistrationModalState(false));
  //     api.dispatch(setLoginModalState(true));
  /*
TODO: refreshToken get method

        const refresh = api.getState().authentication.refreshToken;

        if (refresh) {
            const refreshResult = await baseQuery({url: '/auth/refresh/', body: {refresh}, method: "POST"}, api, extraOptions);

            if (refreshResult.data) {
                api.dispatch(setAuthInfo({
                    accessToken: refreshResult.data.data.access,
                    refreshToken: refreshResult.data.data.refresh,
                }))

                result = await baseQuery(args, api, extraOptions)
            } else if (refreshResult.error && (refreshResult.error.status === 401 || refreshResult.error.status === 403)) {
                logout();
            } else {
                api.dispatch(setApplicationError({status: 500, error: ""}))
            }
        } else {
            logout();
        }

 */
  // }
  return result;
};
