import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import InnerPane from "../../../components/inner-pane/inner-pane";
import css from "./style.module.css";
import Input from "../../../components/form3/input";
import copy from "../../../images/items/copy.svg";
import classNames from "classnames";
import { toast } from "react-toastify";
import { successConfig } from "../../../utils/toastify.config";
import arrowLeft from "../../../images/arrow-left.svg";
import Loader from "../../../components/loader/Loader";
import Button from "../../../components/button/button";
import { toCurrencyString } from "../../../utils/validation";
import { useAuth } from "../../../services/auth";
import CancelBonus from "../../my-bonus/cancel-bonus/cancel-bonus";
import MaskInput from "../../../components/form2/mask-input-3";
import TimerComponent from "../../my-bonus/available-bonuses/timer/available-at-timer";
import { useMediaQuery } from "beautiful-react-hooks";
import { loadBaseUrl, PROTOCOL } from "../../../app/api/api.config";
import DescriptionBonus from "../../my-bonus/description-bonus/description-bonus";
import { Swiper, SwiperSlide } from "swiper/react";
import SlideControlButton from "../../my-bonus/available-bonuses/slide-control/control";
import hasNoBonus from "../../../images/bonuses/has-no-bonus.svg";
import noBonusImg from "./../../../images/wallet/no_bonus.svg";
import { useDispatch, useSelector } from "react-redux";
import { setNotBonusDep } from "../../../app/app.slice";
import {
  useDepositMutation,
  useFetchExchangeRatesDepositQuery,
} from "../../../app/api/wallet.api";
import {
  useGetActiveBonusQuery,
  useGetBonusSlotQuery,
  useTakeBonusMutation,
} from "../../../app/api/bonus.api";

export default function Kauri({
  paymentMethod,
  setPaymentMethod,
  defaultSelectedBonus,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: bonusSlot, isLoading: bonusLoading } = useGetBonusSlotQuery();
  const {
    data: activeBonus,
    isLoading: fetchingActiveBonus,
    error: errorActiveBonus,
  } = useGetActiveBonusQuery();
  const [selectedBonus, selectBonus] = useState(Number(defaultSelectedBonus));
  const selectedBonusObj = useMemo(() => {
    return bonusSlot?.find((item) => item.id === selectedBonus);
  }, [selectedBonus, bonusSlot]);

  const defaultBonus = {
    id: 1,
    title: t("no bonus"),
    image: [noBonusImg],
  };

  // eslint-disable-next-line
  const [activateBonus, { isLoading: bonusActivating }] =
    useTakeBonusMutation();
  const { data: exchangeRates } = useFetchExchangeRatesDepositQuery();
  const [acceptedBonus, setAccepted] = useState(activeBonus);
  const [activeSwiperIndex, setActiveSwiperIndex] = useState();
  const newDataArray = Array.isArray(bonusSlot)
    ? [defaultBonus, ...bonusSlot]
    : [defaultBonus];
  const bonus = newDataArray?.find((item) => item.id === selectedBonus);
  const [replenish, { data: paymentData, isLoading, error }] =
    useDepositMutation();
  const auth = useAuth();
  const [amount, setAmount] = useState("");
  const [showConfirm, setShowConfirm] = useState();
  const notBonusDep = useSelector((state) => state.app.notBonusDep);
  const isTablet = useMediaQuery("(max-width: 1280px)");
  const itemsPerPage = isTablet ? 2 : 4;
  const [amountOut, setAmountOut] = useState("");
  const host = loadBaseUrl().split("/")[0];
  const currency = auth?.user?.currency?.code;

  const slides = useMemo(() => {
    if (!newDataArray) {
      return [];
    }
    const t = newDataArray;

    const res = [];
    const int = Math.trunc(t.length / itemsPerPage);
    const rest = t.length % itemsPerPage;

    for (let i = 0; i < int; i++) {
      res[i] = t.slice(i * itemsPerPage, itemsPerPage * (i + 1));
    }
    if (rest > 0) {
      res.push(t.slice(-rest));
    }
    for (let i = 0; i < itemsPerPage; i++) {
      if (!res[res.length - 1][i]) {
        res[res.length - 1][i] = {};
      }
    }

    return res;
  }, [bonusSlot, itemsPerPage]);

  const onPayClick = () => {
    if (
      selectedBonus &&
      acceptedBonus &&
      acceptedBonus.id !== selectedBonus &&
      selectedBonus !== 1
    ) {
      //ЕСли есть активный бонус и юзер выбрал другой, то вывести препреждение, это не Без бонуса, после подверждения бонус будет деактивирован
      setShowConfirm(true);
    } else {
      depositRequest();
    }
  };
  const [timeBonusAvalailableAt, setTimeBonusAvalailableAt] = useState(null);
  const requestCurrency = paymentMethod.currency.includes("USDT")
    ? "USDT"
    : paymentMethod.currency;
  const depositRequest = () => {
    if (selectedBonus && acceptedBonus?.id !== selectedBonus) {
      //Если бонус выбран, но это не тот бонус, который был выбран ранее, нужно активировать новый бонус
      activateBonus({ slotId: selectedBonus });
    }
    if (selectedBonus === 1) {
      dispatch(setNotBonusDep(true));
    }
    if (!paymentData && !isLoading) {
      replenish({
        provider: paymentMethod.provider,
        requisites: {
          currency: paymentMethod.currency,
        },
      });
    }
  };
  const currencyPair = useMemo(() => {
    if (!auth || !exchangeRates) {
      return null;
    } else if (auth.user.currency.code === paymentMethod.currency) {
      return { exchange: 1 };
    } else {
      return exchangeRates.find(
        (currency) =>
          currency.code_from === auth.user.currency?.code &&
          currency.code_to === requestCurrency
      );
    }
  }, [auth, exchangeRates]);

  useEffect(() => {
    if (selectedBonusObj?.available_at && !selectedBonusObj?.available) {
      setTimeBonusAvalailableAt(selectedBonusObj.available_at);
    } else if (timeBonusAvalailableAt) {
      setTimeBonusAvalailableAt(null);
    }
  }, [selectedBonus]);

  useEffect(() => {
    if (activeBonus) {
      setAccepted(activeBonus);
    }
    if (errorActiveBonus) {
      setAccepted(null);
    }
  }, [errorActiveBonus, activeBonus]);

  useEffect(() => {
    if (newDataArray && !selectedBonus) {
      //Если при загрузке страницы были получены бонусы и при этом еще не был получен/выбран активный бонус, то выберем первый
      selectBonus(newDataArray[0]?.id);
      dispatch(setNotBonusDep(false));
    }
    // eslint-disable-next-line
  }, [selectedBonus, newDataArray]);

  useEffect(() => {
    if (acceptedBonus && acceptedBonus.id) {
      //Если есть активынй бонус
      selectBonus(acceptedBonus.id);
    } else if (!acceptedBonus && selectedBonus) {
      //если с бека пришло что активного бонуса нет, а в стейте он есть, значит надо очисить стейт
      // selectBonus(null);
    }
    // eslint-disable-next-line
  }, [acceptedBonus]);

  const getNumberValue = (val) => {
    if (typeof val === "number") {
      return parseFloat(val).toFixed(5);
    }
    // Удаление всех нецифровых символов, кроме точки
    const cleanedValue = val
      .toString()
      .replace(/[^\d.]/g, "")
      .replace(/^([^.]*\.)|\./g, "$1");

    // Разбиение числа на целую и дробную части
    const [integerPart, decimalPart] = cleanedValue.split(".");

    // Добавление пробелов после каждых трех цифр до точки
    let formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      " "
    );

    // Добавление точки и дробной части
    let formattedValue = formattedIntegerPart;
    if (decimalPart !== undefined /*&& decimalPart !== ''*/) {
      formattedValue += `.${decimalPart.slice(0, 5)}`;
    }

    return formattedValue;
  };

  useEffect(() => {
    if (!selectedBonusObj) return;

    const amountString = String(amount).replace(/\s/g, "");
    const maxAmount = selectedBonusObj?.deposit_max_amount;
    const minAmount =
      selectedBonusObj?.deposit_min_amount?.toStringWithSubUnits(
        auth.user.currency?.subunits
      );

    const condition =
      (maxAmount && amountString > maxAmount) ||
      (minAmount && amountString < minAmount);
  }, [selectedBonusObj, auth.user.currency?.subunits, amount]);

  return (
    <InnerPane paneClass={css.inner_pane} className={css.inner_pane_container}>
      <div
        className={classNames(css.wrapper, { [css.kauri_top]: paymentData })}
      >
        <div
          className={classNames(css.detail, css.kauri, {
            [css.kauri_top]: paymentData,
          })}
        >
          <div className={css.detail_backward__wraper}>
            <div
              className={css.backward}
              onClick={() => setPaymentMethod(null)}
            >
              <img
                src={arrowLeft}
                className={css.back}
                onClick={() => setPaymentMethod(null)}
                alt=""
              />
            </div>
            <div className={css.payment_icon}>
              <img src={paymentMethod.icon} className={css.icon} alt="" />
            </div>
          </div>
          <div className={css.requisites}>
            <div>
              <div className={css.amount}>
                <MaskInput
                  onChange={(val) => {
                    setAmount(val);
                    if (val) {
                      const newAmountOut = val * (currencyPair?.exchange ?? 0);
                      if (newAmountOut && !isNaN(newAmountOut)) {
                        setAmountOut(val * currencyPair.exchange);
                      }
                    } else {
                      setAmountOut("");
                    }
                  }}
                  value={amount}
                  disabled={!!paymentData}
                  icon={auth.user.currency?.sign}
                  currencySubunits={auth.user.currency?.subunits}
                  name="currency-from"
                  scale={auth.user.currency?.subunits}
                  className={css.amoutn_input}
                />
              </div>
              <div className={css.currencies}>
                <MaskInput
                  value={amountOut}
                  type={"text"}
                  onChange={(val) => {
                    setAmountOut(val);
                    if (val) {
                      const newAmount = val / currencyPair.exchange;
                      if (newAmount && !isNaN(newAmount)) {
                        setAmount(newAmount);
                      }
                    } else {
                      setAmount("");
                    }
                  }}
                  icon={
                    <div className={css.outputCurrencyName}>
                      <span>{paymentMethod.currency}</span>
                    </div>
                  }
                  currencySubunits={5}
                  disabled={true}
                  name="currency-out"
                />
                <div className={css.currenciesRateTitle}>
                  {t("Exchange rate")}
                </div>
              </div>
            </div>
          </div>
          {(paymentMethod.min_limit || paymentMethod.max_limit) && (
            <div className={css.deposit_limits}>
              {paymentMethod.min_limit && (
                <div>
                  {t("Min amount deposit")} -{" "}
                  {toCurrencyString(
                    paymentMethod?.min_limit.toStringWithSubUnits(
                      auth.user.currency?.subunits
                    )
                  )}{" "}
                  {auth.user.currency?.sign}
                </div>
              )}
              {paymentMethod.max_limit && (
                <div>
                  {t("Max amount deposit")} -{" "}
                  {toCurrencyString(
                    paymentMethod?.max_limit.toStringWithSubUnits(
                      auth.user.currency?.subunits
                    )
                  )}{" "}
                  {auth.user.currency?.sign}
                </div>
              )}
            </div>
          )}
          <div className={css.processDep_info}>
            {t("to")}
            <span className={css.processDep_info__color}>
              {t("improve the process")}
            </span>
            {t(
              "of obtaining details, please create requests to top up your balance with"
            )}
            <span className={css.processDep_info__color}>
              {t("NOT rounded amounts")}
            </span>
            {t("for example")}
          </div>

          {!paymentData && (
            <Button
              isLoading={isLoading || bonusActivating}
              className={classNames(
                css.bonus__button,
                css.bonus__button_kauri,
                { [css.button_kauri_notBonus]: selectedBonus === 1 }
              )}
              onClick={onPayClick}
              variant="violet"
              disabled={
                !amount ||
                amount === "0" ||
                (amount &&
                  paymentMethod.min_limit &&
                  parseFloat(amount) < paymentMethod.min_limit / 100) ||
                (amount &&
                  paymentMethod.min_limit &&
                  parseFloat(amount) > paymentMethod.max_limit / 100) ||
                (selectedBonusObj &&
                  selectedBonusObj?.deposit_max_amount &&
                  String(amount).replace(/\s/g, "") >
                    selectedBonusObj?.deposit_max_amount) ||
                (selectedBonusObj?.deposit_min_amount &&
                  String(amount).replace(/\s/g, "") <
                    selectedBonusObj?.deposit_min_amount.toStringWithSubUnits(
                      auth.user.currency?.subunits
                    )) ||
                timeBonusAvalailableAt
              }
            >
              {t("top up")}
            </Button>
          )}
          {isTablet &&
          !notBonusDep &&
          amount &&
          selectedBonusObj &&
          ((selectedBonusObj?.deposit_max_amount &&
            String(amount).replace(/\s/g, "") >
              selectedBonusObj?.deposit_max_amount) ||
            (selectedBonusObj?.deposit_min_amount &&
              String(amount).replace(/\s/g, "") <
                selectedBonusObj?.deposit_min_amount.toStringWithSubUnits(
                  auth.user.currency?.subunits
                ))) ? (
            <div className={css.bonus__notice}>
              {t("Bonus conditions not met")}
            </div>
          ) : (
            <div className={css.bonus__notice}>
              <br />
            </div>
          )}
        </div>
        <div
          className={classNames(css.detail_blok__bonus, css.cripto, {
            [css.alt_kauri]: paymentData,
          })}
        >
          <div
            className={classNames(
              css.alt,
              css.card_deposit,
              css.bonus,
              css.cripto,
              { [css.alt_kauri]: paymentData }
            )}
          >
            {bonusLoading ||
              fetchingActiveBonus ||
              (bonusSlot && isLoading && <Loader />)}
            {((bonusLoading || fetchingActiveBonus) && !acceptedBonus) ||
            paymentData ||
            isLoading ||
            !newDataArray ? null : (
              <>
                <div className={css.available_block}>
                  <Swiper
                    onSlideChange={(e) => setActiveSwiperIndex(e.activeIndex)}
                  >
                    {slides.map((slide, slideIndex) => (
                      <SwiperSlide key={slideIndex}>
                        <div className={css.cards_wraper}>
                          {slide?.map((item, index) =>
                            item.id ? (
                              <img
                                className={classNames(css.bonus_card, {
                                  [css.bonus_card__active]:
                                    selectedBonus === item.id,
                                })}
                                key={item.id}
                                onClick={() => {
                                  selectBonus(item.id);
                                }}
                                src={
                                  item.id === 1
                                    ? noBonusImg
                                    : `${PROTOCOL}${host}/uploads/${item.image}`
                                }
                                alt=""
                              />
                            ) : (
                              <div className={css.no_bonus}>
                                <img src={hasNoBonus} alt="" />
                                <span className={css.no_bonus__title}>
                                  {t("has no bonus")}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                    {newDataArray?.length > itemsPerPage && (
                      <div className={css.pagination}>
                        {slides.map((_, index) => (
                          <SlideControlButton
                            key={index}
                            i={index}
                            isActive={index === activeSwiperIndex}
                          />
                        ))}
                      </div>
                    )}
                  </Swiper>
                </div>
                <span className={css.available_block__text}>
                  {t("select bonus")}
                </span>
                <div className={css.divider} />
              </>
            )}
            <div
              className={classNames(css.payment_crypto, {
                [css.hidden]: !paymentData,
              })}
            >
              <div className={css.input}>
                <label>{t("Casino wallet")}</label>
                <div className={css.inputs}>
                  <Input
                    defaultValue={paymentData?.addr}
                    icon={
                      <img
                        onClick={() => {
                          navigator.clipboard.writeText(paymentData?.addr);
                          const notify = () =>
                            toast(t("address copied"), {
                              ...successConfig,
                            });
                          notify();
                        }}
                        src={copy}
                        className={classNames(css.pointer, css.input_icon)}
                        alt=""
                      />
                    }
                    className={css.crypto_address}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className={css.qr_block}>
                <div className={css.qr_code}>
                  <img
                    src={`data:image/jpeg;base64,${paymentData?.qr}`}
                    alt=""
                  />
                </div>
                <div className={css.requisites_text}>
                  {t("Requisites text 2")}
                </div>
              </div>
            </div>
            {timeBonusAvalailableAt ? (
              <TimerComponent dateString={timeBonusAvalailableAt} />
            ) : (
              <div className={css.bonus__notice}>
                {!isTablet &&
                !notBonusDep &&
                amount &&
                selectedBonusObj &&
                ((selectedBonusObj?.deposit_max_amount &&
                  String(amount).replace(/\s/g, "") >
                    selectedBonusObj?.deposit_max_amount) ||
                  (selectedBonusObj?.deposit_min_amount &&
                    String(amount).replace(/\s/g, "") <
                      selectedBonusObj?.deposit_min_amount.toStringWithSubUnits(
                        auth.user.currency?.subunits
                      ))) ? (
                  <>
                    {t("Bonus conditions not met")}
                    {selectedBonus &&
                    acceptedBonus &&
                    acceptedBonus.id != selectedBonus ? (
                      <br />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {selectedBonus !== 1 &&
                selectedBonus &&
                acceptedBonus &&
                acceptedBonus.id != selectedBonus ? (
                  <span className={css.text_active__bonus}>
                    {t("You already have an active bonus")}&nbsp;
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(
              css.description_block,
              css.kauri_description,
              { [css.description_block__kauri]: !paymentData },
              { [css.description_block_noBonus]: selectedBonus === 1 }
            )}
          >
            {bonusLoading ||
              fetchingActiveBonus ||
              (bonusSlot && isLoading) ||
              (!newDataArray && <Loader />)}
            <div
              className={classNames(css.card_description__wraper, {
                [css.hidden]: !paymentData,
              })}
            >
              <img
                className={css.bonus_card__kauri}
                // key={bonus.id}
                src={
                  notBonusDep
                    ? noBonusImg
                    : `${PROTOCOL}${host}/uploads/${bonus?.image}`
                }
                alt=""
              />
            </div>
            {selectedBonus === 1 || notBonusDep ? (
              <div className={css.description_block__title}>
                {t("no bonus")}
              </div>
            ) : (
              <DescriptionBonus
                bonus={bonus}
                variant={!paymentData ? "wallet" : "deposit_kauri"}
                currency={currency}
              />
            )}
          </div>
        </div>
      </div>
      <CancelBonus
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        onCancelSuccess={depositRequest}
      />
    </InnerPane>
  );
}
