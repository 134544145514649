import css from "./vip-club.module.css";
import { useTranslation } from "react-i18next";

export default function VipClub() {
  const { t } = useTranslation();

  return (
    <div className={css.vip_background}>
      <div className={css.vip_wraper}>
        <div className={css.vip_border}></div>
        <div className={css.vip_cards}>
          <div className={css.card_item}>
            <div className={css.card_item_name}>{t("Earl")}</div>
          </div>
          <div className={css.card_item}>
            <div className={css.card_item_name}>{t("Marquis")}</div>
          </div>
          <div className={css.card_item}>
            <div className={css.card_item_name}>{t("Duke")}</div>
          </div>
          <div className={css.card_item}>
            <div className={css.card_item_name}>{t("King")}</div>
          </div>
          <div className={css.card_item}>
            <div className={css.card_item_name}>{t("God")}</div>
          </div>
        </div>
        <div className={css.vip_info}>
          <h1 className={css.title}>{t("What is a VIP club?")}</h1>
          <div className={css.sub_title}>
            {t(
              "The condition for assigning VIP status is the amount of deposits from 4 500 000 RUB"
            )}
          </div>
          <div className={css.vip_info_items}>
            <div className={css.vip_info_item}>{t("Individual Cashback")}</div>
            <div className={css.vip_info_item}>{t("Birthday gift")}</div>
            <div className={css.vip_info_item}>{t("New Year's gift")}</div>
            <div className={css.vip_info_item}>
              {t("accelerated withdrawal of funds")}
            </div>
            <div className={css.vip_info_item}>{t("additional bonuses")}</div>
            <div className={css.vip_info_item}>{t("personal manager")}</div>
            <div className={css.vip_info_item}>
              {t("Increased withdrawal limit")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
