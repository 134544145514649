import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    balance: null,
    notification: null,
    cacheKey: null,
    showGreeting: false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setBalance(state, action) {
            state.balance = {
                balance: action.payload.balance,
                bonus_balance: action.payload.bonus_balance
            }
        },
        setCacheKey(state, action) {
            state.cacheKey = action.payload?.cacheKey || null
            if (action.payload?.showGreeting) {
                state.showGreeting = action.payload.showGreeting
            } else {
                state.showGreeting = false
            }
        }
    }
});

export const {setBalance, setCacheKey} = userSlice.actions;

export default userSlice.reducer;