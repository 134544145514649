import ChangePass from "./change-pass";
import css from "./safety.module.css";
import SelfRestraint from "./self-restraint";
import TwoFactorAuthentication from "./two_factor_authentication.js";

export default function Safety() {
    return (
        <div className={css.wraper}>
            <ChangePass />
            <TwoFactorAuthentication />
            <SelfRestraint />
        </div>
    )
};