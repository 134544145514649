import React, {useEffect} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {setGame} from "../../features/games/game.slice";
import {useDispatch} from "react-redux";

export default function GameRedirect({mode}) {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    useEffect(() => {
        dispatch(setGame({id: decodeURIComponent(params.id), provider: params.provider, mode}))
    });

    return <Redirect to={from}/>
}