import GameLayout from "./game-layout";
import { returnScrollPosition } from "../../components/scrollbar/scrollbar";
import Game from "../../features/games/game/game";
import { useDispatch, useSelector } from "react-redux";
import detectDevice from "../../utils/detect-device";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { setGame } from "../../features/games/game.slice";
import GamesController from "../../features/games/games-controller/games-controller";

/**
 *   @Component
 *   Компонент отрисовывающий страницу с игрой
 *   Будет что-то выводить только если в хранилище сохранена игра state.game.game
 *
 * */
export default function GameContainer() {
  const game = useSelector((state) => state.game.game);
  const location = useLocation();
  const device = new detectDevice();
  const dispatch = useDispatch();
  const [lastUrl, setLastUrl] = useState();

  useEffect(() => {
    if (game) {
      window.openSplash();
    } else {
      if (!device.isMobile) {
        window.closeSplash();
      }
    }
  }, [game]);

  useEffect(() => {
    if (lastUrl === location.pathname) {
      setLastUrl(null);
      dispatch(setGame(null));
      localStorage.removeItem("pageSection");
      setTimeout(() => {
        returnScrollPosition();
      }, 100);
    }
  });

  if (!game?.id) {
    return null;
  }

  return (
    <GameLayout>
      <Game isDemo={game.mode === "demo"} game={game} />
      {device.type === "desktop" && <GamesController hasLayout={false} />}
      {device.type === "desktop" && <div style={{ height: 100 }} />}
    </GameLayout>
  );
}
