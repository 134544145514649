import css from "./safety.module.css";
import self_restraint from "./../../images/profile/self_restraint.svg";
import { useTranslation } from "react-i18next";
import Select from "../../components/form3/select";
import Button from "../../components/button/button";
import { useEffect, useState } from "react";
import { useSelfBlockMutation } from "../../app/api/user.api";
import { toast } from "react-toastify";
import classNames from "classnames";
import Modal from "../../components/modal/modal";
import { useAuth } from "../../services/auth";
import { useHistory } from "react-router-dom";

export default function SelfRestraint() {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const listTimer = [1, 3, 7];
  const [selectedItem, setSelectedItem] = useState("never");
  const [showConfirm, setShowConfirm] = useState(false);
  const [selfBlock, { data, isLoading, error }] = useSelfBlockMutation();

  useEffect(() => {
    if (data) {
      const notify = () =>
        toast(t("self-restraint success"), {
          theme: "dark",
          type: "success",
        });
      notify();
      setShowConfirm(false);

      setTimeout(() => {
        auth.signout(() => {
          history.push("/");
        });
      }, 2000);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const notify = () =>
        toast(error.data.message, {
          theme: "dark",
          type: "error",
        });
      notify();
      setShowConfirm(false);
    }
  }, [error]);

  const handleChange = (item) => {
    setSelectedItem(item);
  };

  const onSubmit = async () => {
    selfBlock({ days: selectedItem.value });
  };

  return (
    <div className={css.self_restraint}>
      <img src={self_restraint} alt="" />
      <span className={css.self_restraint__title}>{t("self-restraint")}</span>
      <Select
        options={listTimer.map((day, index) => ({
          value: day,
          key: `option-${day}-${index}`,
          label: t("limit day", {
            count: day,
          }),
        }))}
        className={css.self_restraint__select}
        value={selectedItem}
        onChange={handleChange}
      />
      <span className={css.self_restraint__text}>
        {t("self-restraint text")}
      </span>
      <Button onClick={() => setShowConfirm(true)} isLoading={isLoading}>
        {t("to plug")}
      </Button>

      {showConfirm && (
        <Modal
          isOpen={showConfirm}
          closeModal={() => setShowConfirm(false)}
          className={css.modal}
          theme={"self-restraint"}
        >
          <div className={css.error}>
            <div className={css.error_header}>
              <div className={css.error_title}>{t("self-restraint")}</div>
            </div>

            <div className={css.error_text}>
              <span>{t("self-restraint confirm text")}</span>
            </div>
            <div
              className={classNames(
                css.btn_container,
                css.btn_container__width,
              )}
            >
              <Button onClick={onSubmit}>{t("Confirm")}</Button>
              <Button
                onClick={() => {
                  setShowConfirm(false);
                }}
                variant={"blue"}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
