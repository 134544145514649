import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import { useGetBalanceQuery } from "../../../app/api/user.api";
import css from "./balance.module.css";
import { useAuth } from "../../../services/auth";
import Button from "../../../components/button/button.2";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBalance } from "../user.slice";
import classNames from "classnames";
import { setGame } from "../../games/game.slice";
import Loader from "../../../components/loader/Loader";

export default function Balance() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const { data } = useGetBalanceQuery();
  const auth = useAuth();
  const balance = useSelector((state) => state.user.balance);
  const button = useRef();
  const container = document.getElementById("container");
  const containerWidth = container?.clientWidth;
  const history = useHistory();
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game.game);
  const [isAnimating, setIsAnimating] = useState(false);

  const listener = function (e) {
    e = e || window.event;
    var start = 0,
      diff = 0;
    let source = e.changedTouches
      ? e.changedTouches[e.changedTouches.length - 1]
      : e;
    if (source.pageX) start = source.pageX;
    else if (source.clientX) start = source.clientX;

    document.body.ontouchmove = document.body.onmousemove = function (e) {
      e = e || window.event;
      let end = 0;
      let source = e.changedTouches
        ? e.changedTouches[e.changedTouches.length - 1]
        : e;
      if (source.pageX) end = source.pageX;
      else if (source.clientX) end = source.clientX;

      diff = end - start;

      if (
        diff <= containerWidth + 2 - button.current.clientWidth &&
        diff >= 0
      ) {
        button.current.style.left = diff + "px";
      }
    };

    /*document.body.onmouseleave = */ document.body.ontouchend =
      document.body.onmouseup = function () {
        button.current.classList.add(css.automovement);

        if (containerWidth - diff > button.current.clientWidth) {
          //фикс чтобы кнопка не съезжала
          button.current.style.left = 0 + "px";
        }
        /*  document.body.onmouseleave = */ document.body.ontouchmove =
          document.body.onmousemove =
          document.body.ontouchend =
          document.body.onmouseup =
            null;

        if (diff > containerWidth / 1.7 - button.current.clientWidth / 2) {
          history.push("/wallet");
        }
        setTimeout(() => {
          button.current?.classList.remove(css.automovement);
        }, 300);
        button.current.style.left = 0;
      };
  };

  useEffect(() => {
    if (data && !balance) {
      dispatch(setBalance(data));
    }
  }, [data]);

  useEffect(() => {
    if (button.current && container) {
      button.current.ontouchstart = button.current.onmousedown = listener;
    }

    return () => {
      if (button.current) {
        button.current.ontouchstart = button.current.onmousedown = null;
      }
    };
  }, [containerWidth]);

  return (
    <>
      {balance === null ? (
        <Loader />
      ) : (
        <div className={css.balance} id="container">
          <div className={css.currency}>
            <div className={css.line}>
              <span className={classNames(css.sign, css.sign_currency)}>
                {auth.user.currency?.sign}
              </span>
              <span>
                {(balance?.balance / 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  .replace(",", ".")}
              </span>
            </div>
            <div className={css.line}>
              <span className={css.sign}>
                <svg
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.16092 0.301473L6.41214 2.12255e-05L3.66594 2.0748e-05L0.937499 3.24426L3.7066 3.24426L6.16092 0.301473ZM4.36399 3.24449L9.5506 3.24449L8.14057 1.55318L6.95761 0.134408L5.1699 2.2779L4.36399 3.24449ZM0.970683 3.75844L6.12712 9.35407L3.66124 3.75844L0.970683 3.75844ZM9.7068 3.75802L9.25742 3.75802L7.36805 3.75844L4.20777 3.75844L6.94113 9.96051L6.95869 10L9.563 4.08549L9.7068 3.75802ZM7.79371 9.34612L12.942 3.75867L10.2538 3.75867L7.79371 9.34612ZM12.9756 3.24427L10.2463 1.6186e-06L7.50202 1.14143e-06L10.2074 3.24426L12.9756 3.24427Z"
                    fill="#48578B"
                  />
                </svg>
              </span>
              <span>
                {(balance?.bonus_balance / 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  .replace(",", ".")}
              </span>
            </div>
          </div>
          <Button
            referal={button}
            childrenClassName={css.button__children}
            onClick={() => {
              setIsAnimating(true);
              setTimeout(() => {
                setIsAnimating(false);
                history.push("/wallet");
                if (Boolean(game)) dispatch(setGame(null));
              }, 700);
            }}
          >
            <div
              className={classNames(css.button, css.button_radius, {
                [css.mobile]: isMobile,
              })}
            >
              {t("top up")}
            </div>
          </Button>
          <div
            className={classNames(css.button__animation, {
              [css.animate]: isAnimating,
            })}
          ></div>
        </div>
      )}
    </>
  );
}
