import React from 'react';
import {ReactComponent as LiveGames} from "../../images/games/live_games.svg";
import {ReactComponent as BonusBuy} from "../../images/games/bonus_buy.svg";
import {ReactComponent as AllGames} from "../../images/games/all_games.svg";
import {ReactComponent as Popular} from "../../images/games/popular.svg";
import {ReactComponent as NewGames} from "../../images/games/new_games.svg";

export default function GameSectionIcon ({id}) {
    switch (id) {
        case 'live_games': 
        return (
            <LiveGames />
        )
        case 'bonus_buy': 
        return (
            <BonusBuy />
        )
        case 'all_games': 
        return (
            <AllGames />
        )
        case 'popular': 
        return (
            <Popular />
        )
        case 'new_games': 
        return (
            <NewGames />
        )
        default:
            return <svg></svg>;
    }
}