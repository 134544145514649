import css from "./profile.module.css";
import ContentPane from "../../components/content-pane/content-pane";
import { useTranslation } from "react-i18next";
import PersonalData from "./personal-data";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useAuth } from "../../services/auth";
import avatar from "../../images/avatar-profile.svg";
import player from "../../images/ranks/player.svg";
import knight from "../../images/ranks/knight.svg";
import baron from "../../images/ranks/baron.svg";
import viscount from "../../images/ranks/viscount.svg";
import earl from "../../images/ranks/earl.svg";
import marquis from "../../images/ranks/marquis.svg";
import duke from "../../images/ranks/duke.svg";
import king from "../../images/ranks/king.svg";
import god from "../../images/ranks/god.svg";
import playerCard from "../../images/ranks/cards ranks/playerCard.svg";
import knightCard from "../../images/ranks/cards ranks/knightCard.svg";
import baronCard from "../../images/ranks/cards ranks/baronCard.svg";
import viscountCard from "../../images/ranks/cards ranks/viscountCard.svg";
import earlCard from "../../images/ranks/cards ranks/earlCard.svg";
import marquisCard from "../../images/ranks/cards ranks/marquisCard.svg";
import dukeCard from "../../images/ranks/cards ranks/dukeCard.svg";
import kingCard from "../../images/ranks/cards ranks/kingCard.svg";
import godCard from "../../images/ranks/cards ranks/godCard.svg";
import maskLineDeposit from "./../../images/ranks/musk-line-deposit.svg";
import { useHistory } from "react-router-dom";
import exit from "./../../images/exit.svg";
import Social from "./social";
import Safety from "./safety";
import { useMediaQuery } from "beautiful-react-hooks";
import Button from "../../components/button/button";
import Modal from "../../components/modal/modal";
import close from "./../../images/close_modal_touch.svg";

export default function Profile() {
  const isTablet = useMediaQuery("(max-width: 1400px)");
  const isMobile = useMediaQuery("(max-width: 960px)");
  const { t } = useTranslation();
  const history = useHistory();
  const [openSettings, setOpenSettigs] = useState(true);
  const [openPrivelegeMenu, setOpenPrivelegeMenu] = useState(false);
  const [openPrivelegeMenuModal, setOpenPrivelegeMenuModal] = useState(false);
  const auth = useAuth();

  const ranks = {
    1: [
      player,
      playerCard,
      "Player",
      "support 24/7",
      "weekly bonus",
      "200 000",
      "450 000",
      "1 000 000",
    ],
    2: [
      knight,
      knightCard,
      "Knight",
      "support 24/7",
      "weekly bonus",
      "300 000",
      "1 000 000",
      "2 500 000",
    ],
    3: [
      baron,
      baronCard,
      "Baron",
      "support 24/7",
      "weekly bonus",
      "450 000",
      "1 300 000",
      "3 100 000",
    ],
    4: [
      viscount,
      viscountCard,
      "Viscount",
      "support 24/7",
      "weekly bonus",
      "900 000",
      "1 800 000",
      "6 500 000",
    ],
    5: [
      earl,
      earlCard,
      "Earl",
      "personal manager",
      "additional bonuses",
      "4 500 000",
      "No Limit",
      "No Limit",
      "5 000",
      "accelerated withdrawal of funds",
    ],
    6: [
      marquis,
      marquisCard,
      "Marquis",
      "personal manager",
      "additional bonuses",
      "7 000 000",
      "No Limit",
      "No Limit",
      "8 000",
      "accelerated withdrawal of funds",
    ],
    7: [
      duke,
      dukeCard,
      "Duke",
      "personal manager",
      "additional bonuses",
      "10 000 000",
      "No Limit",
      "No Limit",
      "10 000",
      "accelerated withdrawal of funds",
    ],
    8: [
      king,
      kingCard,
      "King",
      "personal manager",
      "additional bonuses",
      "15 000 000",
      "No Limit",
      "No Limit",
      "15 000",
      "accelerated withdrawal of funds",
    ],
    9: [
      god,
      godCard,
      "God",
      "personal manager",
      "additional bonuses",
      "25 000 000",
      "No Limit",
      "No Limit",
      "20 000",
      "accelerated withdrawal of funds",
    ],
  };

  const [
    rankImg,
    rankCard,
    rankTitle,
    support,
    bonus,
    withdrawalDay,
    withdrawalWeek,
    withdrawalMonth,
    giftNY,
  ] = useMemo(() => {
    const rankId = auth.user.rank === null ? 1 : auth.user.rank_id;
    return ranks[rankId] || [];
    // eslint-disable-next-line
  }, [auth.user.rank, auth.user.rank_id]);

  const now = auth?.user?.rank ? auth.user.rank.cashback_percent : "";
  const newAvatar =
    auth.user === null
      ? ""
      : auth.user.avatar_image === null || auth.user.avatar_image === undefined
        ? avatar
        : auth.user.avatar_image;
  const depositSum =
    auth.user.dep_sum === undefined
      ? 0
      : new Intl.NumberFormat("ru-RU").format(auth.user.dep_sum / 100);
  const depositSumMonth =
    auth.user.dep_sum_month === undefined
      ? 0
      : new Intl.NumberFormat("ru-RU").format(auth.user.dep_sum_month / 100);
  const depositSumMonthCalc =
    auth.user.dep_sum_month === undefined ? 0 : auth.user.dep_sum_month / 100;
  const depositAmountCalc =
    auth.user.rank === null
      ? ""
      : auth.user.rank?.next_rank === null
        ? ""
        : auth.user.rank?.next_rank?.deposit_amount === null
          ? "0"
          : auth.user.rank?.next_rank?.deposit_amount / 100;
  const depositAmount =
    auth.user.rank === null
      ? ""
      : new Intl.NumberFormat("ru-RU").format(
          auth.user.rank?.deposit_amount === null
            ? "0"
            : auth.user.rank?.deposit_amount / 100
        );
  const depositAmountLine =
    auth.user.rank === null
      ? ""
      : auth.user.rank?.next_rank === null
        ? ""
        : new Intl.NumberFormat("de-DE").format(
            auth.user.rank?.next_rank?.deposit_amount === null
              ? "0"
              : auth.user.rank?.next_rank?.deposit_amount / 100
          );
  const depositLine = Math.min(
    (depositSumMonthCalc * 100) / depositAmountCalc,
    100
  );
  const currency = auth?.user ? auth.user.currency?.code : "";
  const birthdayGift =
    auth.user.rank === null
      ? ""
      : new Intl.NumberFormat("ru-RU").format(
          auth.user.rank.present_amount / 100
        );

  return (
    <div className={css.profile}>
      <div className={css.profile_menu}>
        <div className={css.settings}>
          <div
            className={classNames(css.menu_settings, {
              [css.menu_active]: openSettings,
            })}
            onClick={() => {
              setOpenSettigs(true);
            }}
          >
            {t("settings")}
          </div>
          <div
            className={classNames(css.menu_safety, {
              [css.menu_active]: !openSettings,
            })}
            onClick={() => {
              setOpenSettigs(false);
            }}
          >
            {t("safety")}
          </div>
        </div>
        <div
          className={css.settings_exit}
          onClick={() => {
            window.openSplash();
            auth.signout(() => {
              window.closeSplash(100);
              history.push("/");
            });
          }}
        >
          <img src={exit} alt="" />
          {!isMobile && <span>{t("Log out")}</span>}
        </div>
      </div>
      <div
        className={classNames(css.profile_settings, {
          [css.hiden]: !openSettings,
        })}
      >
        <div className={css.block}>
          <div className={classNames(css.block_avatar_rank)}>
            <div className={css.block_avatar__social}>
              <ContentPane className={classNames(css.avatar_wraper)}>
                <div className={classNames(css.avatar_block)}>
                  <img
                    className={classNames(css.avatar)}
                    src={newAvatar}
                    alt=""
                  />
                  <img
                    className={classNames(css.image_rank)}
                    src={rankImg}
                    alt=""
                  />
                </div>
                <div className={css.title_blcok}>
                  <span className={classNames(css.nickname_title)}>
                    {auth.user.nickname}
                  </span>
                  <span className={classNames(css.rank_title)}>
                    {t("rank")}{" "}
                    <span className={classNames(css.rank_title__span)}>
                      {t(rankTitle)}
                    </span>
                  </span>
                </div>
                {isTablet && (
                  <>
                    <DepositAmount
                      props={{
                        depositSum,
                        depositSumMonth,
                        currency,
                        depositLine,
                        depositAmountLine,
                      }}
                      variant="menu"
                    />
                    {isMobile && (
                      <Priveleges
                        props={{
                          support,
                          now,
                          birthdayGift,
                          giftNY,
                          depositAmount,
                          withdrawalDay,
                          withdrawalWeek,
                          withdrawalMonth,
                          bonus,
                          currency,
                          isMobile,
                          openPrivelegeMenu,
                        }}
                      />
                    )}
                    <Button
                      className={css.privilege_btn}
                      onClick={() => {
                        isTablet && !isMobile
                          ? setOpenPrivelegeMenuModal(!openPrivelegeMenuModal)
                          : setOpenPrivelegeMenu(!openPrivelegeMenu);
                      }}
                    >
                      {openPrivelegeMenu && isMobile
                        ? t("hide")
                        : t("list of privileges")}
                    </Button>
                    {!isMobile && <Social />}
                  </>
                )}
                <Modal isOpen={openPrivelegeMenuModal} theme={"privelege"}>
                  <div className={css.modal_privelege}>
                    <div
                      className={css.modal_close}
                      onClick={() => setOpenPrivelegeMenuModal(false)}
                    >
                      <img src={close} alt="" />
                    </div>
                    <RankCard
                      props={{
                        rankCard,
                        rankTitle,
                        depositSum,
                        depositSumMonth,
                        currency,
                        depositLine,
                        depositAmountLine,
                        support,
                        now,
                        birthdayGift,
                        giftNY,
                        depositAmount,
                        withdrawalDay,
                        withdrawalWeek,
                        withdrawalMonth,
                        bonus,
                      }}
                    />
                  </div>
                </Modal>
              </ContentPane>
              {!isTablet && !isMobile && <Social />}
            </div>
            <ContentPane
              className={classNames(
                css.pane,
                css.pane_padding,
                css.personal_data
              )}
              paneClass={classNames(
                css.without_shadow,
                css.pane__content,
                css.pane__content_padding
              )}
            >
              <PersonalData />
            </ContentPane>
            {!isTablet && (
              <RankCard
                variant="modal_list"
                props={{
                  rankCard,
                  rankTitle,
                  depositSum,
                  depositSumMonth,
                  currency,
                  depositLine,
                  depositAmountLine,
                  support,
                  now,
                  birthdayGift,
                  giftNY,
                  depositAmount,
                  withdrawalDay,
                  withdrawalWeek,
                  withdrawalMonth,
                  bonus,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(css.profile_safety, {
          [css.hiden]: openSettings,
        })}
      >
        <Safety />
      </div>
    </div>
  );
}

function ProgressLine({ depositLine, variant }) {
  return (
    <>
      <div className={classNames(css.deposit_line, css[variant])}>
        <img src={maskLineDeposit} className={css.deposit_line__mask} alt="" />
        <div
          className={css.deposit_line__active}
          style={{ width: `${depositLine}%` }}
        ></div>
      </div>
      <div
        className={css.deposit_line__activeLight}
        style={{ left: depositLine ? `calc(${depositLine}% - 12px)` : "2px" }}
      />
    </>
  );
}

function DepositAmount({ props, variant }) {
  const { currency, depositLine, depositAmountLine, depositSumMonth } = props;
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className={classNames(css.progress_line, css[variant])}>
      <span className={css.progress_line__title}>
        {t("number of deposits")}
      </span>
      <span className={css.progress_line__text}>
        {depositSumMonth} {currency}
      </span>
      <div className={css.progress_line__container}>
        <div className={css.deposit__line__wrapper}>
          <div className={css.deposit_line__progressLabels}>
            <span className={css.deposit_line__progressLabelsPoint} />
            <span className={css.deposit_line__progressLabelsPoint} />
          </div>
          <ProgressLine depositLine={depositLine} variant={variant} />
        </div>
      </div>
      <div className={css.deposit_line__progressCaption}>
        <span>0</span>
        <span>{auth?.user.rank_id === 9 ? "Max Rank" : depositAmountLine}</span>
      </div>
    </div>
  );
}

function Priveleges({ props }) {
  const {
    support,
    now,
    birthdayGift,
    giftNY,
    withdrawalDay,
    withdrawalWeek,
    withdrawalMonth,
    bonus,
    currency,
    isMobile,
    openPrivelegeMenu,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={classNames(css.block_privileges, {
        [css.priveleges_hiden]: isMobile && !openPrivelegeMenu,
      })}
    >
      <span>{t(support)}</span>
      {now === ("" || 0) ? (
        ""
      ) : (
        <span>
          {t("cashback")} {now}%
        </span>
      )}
      {birthdayGift === ("" || "0") ? (
        ""
      ) : (
        <span>
          {t("birthday gift")} {birthdayGift} {currency}{" "}
        </span>
      )}
      {giftNY === ("" || undefined) ? (
        ""
      ) : (
        <span>
          {t("new year's gift")} {giftNY} {currency}
        </span>
      )}
      <span>
        {t("withdrawal up to")} {withdrawalDay} {currency} {t("in a day")}
      </span>
      <span>
        {t("withdrawal up to")} {withdrawalWeek} {currency} {t("in week")}
      </span>
      <span>
        {t("withdrawal up to")} {withdrawalMonth} {currency} {t("per month")}
      </span>
      <span>{t(bonus)}</span>
    </div>
  );
}

function RankCard({ props }) {
  const {
    rankCard,
    rankTitle,
    depositSum,
    depositSumMonth,
    currency,
    depositLine,
    depositAmountLine,
    support,
    now,
    birthdayGift,
    giftNY,
    depositAmount,
    withdrawalDay,
    withdrawalWeek,
    withdrawalMonth,
    bonus,
    variant,
  } = props;
  const { t } = useTranslation();

  return (
    <ContentPane className={classNames(css.rank_wraper, css[variant])}>
      <div className={classNames(css.rank_block, css.rank_block__border)}>
        <div className={css.rank_block__wraper}>
          <img
            className={classNames(css.rank_card__filter, css.rank_card__first)}
            src={rankCard}
            alt=""
          />
          <img className={classNames(css.rank_card)} src={rankCard} alt="" />
          <img
            className={classNames(css.rank_card__filter, css.rank__second)}
            src={rankCard}
            alt=""
          />
          <div className={css.rank_block_card}>
            <span className={css.rank_block__player}>{t(rankTitle)}</span>
          </div>
        </div>
        <DepositAmount
          props={{
            depositSum,
            depositSumMonth,
            currency,
            depositLine,
            depositAmountLine,
          }}
        />
        <Priveleges
          props={{
            support,
            now,
            birthdayGift,
            giftNY,
            depositAmount,
            withdrawalDay,
            withdrawalWeek,
            withdrawalMonth,
            bonus,
            currency,
          }}
        />
      </div>
    </ContentPane>
  );
}
