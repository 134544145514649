import ErrorLayout from "./layout";
import image from "../../images/items/500.png"
import css from "./error.module.css";
import {useTranslation} from "react-i18next";

export default function InternalError() {
    const {t} = useTranslation();

    return (
        <ErrorLayout>
            <div className={css.image}>
                <img src={image} alt=""/>
            </div>
            <div className={css.description}>
                <div className={css.title}>{t("error 500 title")}</div>
                <div className={css.text}>{t("error 500 text")}</div>
            </div>
        </ErrorLayout>
    );
}