import css from "../payment-default.module.css";
import blackRabitEHOT from "../../../images/wallet/blackRabitEHOT.svg";
import blackRabitP2P from "../../../images/wallet/blackRabitP2P.svg";
import qiwi from "../../../images/wallet/qiwi.svg";
import p2p from "../../../images/wallet/p2p.svg";
import sber_tinkoff from "../../../images/wallet/sber_tinkoff.svg";
import uMoney from "../../../images/wallet/uMoney.svg";
import Ethereum from "../../../images/wallet/Ethereum.svg";
import Litecoin from "../../../images/wallet/Litecoin.svg";
import Bitcoin from "../../../images/wallet/Bitcoin.svg";
import piastrix from "../../../images/wallet/piastrix.svg";
import tether_erc20 from "../../../images/wallet/tether_erc20.svg";
import tether_bep20 from "../../../images/wallet/tether_bep20.svg";
import tether_trc20 from "../../../images/wallet/tether_trc20.svg";
import dodgecoin from "../../../images/wallet/dodgecoin.svg";
import bnb from "../../../images/wallet/bnb.svg";
import polygon from "../../../images/wallet/polygon.svg";
import busd from "../../../images/wallet/busd.svg";
import matic from "../../../images/wallet/matic.svg";
import tron from "../../../images/wallet/tron.svg";
import dash from "../../../images/wallet/dash.svg";
import telegram from "../../../images/wallet/telegram.svg";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import PaymentItem from "./payment-item";
import piastrix_sbp from "./../../../images/wallet/new/piastrix_sbp.png";
import piastrix_sber from "./../../../images/wallet/new/piastrix_sber.png";
import piastrix_bank from "./../../../images/wallet/new/piastrix_bank.png";
import piastrix_card from "./../../../images/wallet/new/piastrix_card.png";
import piastrix_sbp_sberbank from "./../../../images/wallet/new/piastrix_sbp_sberbank.png";
import piastrix_sbp_tinkoff from "./../../../images/wallet/new/piastrix_sbp_tinkoff.png";
import paycosP2P from "./../../../images/wallet/new/paycos_p2p_106.png";
import wallet_expert_p2p from "./../../../images/wallet/wallet_expert_p2p.svg";
import blackRabbit_p2p_phone from "./../../../images/wallet/blackRabbit_p2p_phone.svg";

function icons(type) {
  return {
    uMoney: {
      icons: [uMoney],
    },
    telegram_pay: {
      icons: [telegram],
    },
    blackRabbit_acquiring: {
      icons: [blackRabitEHOT],
    },
    blackRabbit_payout_crypto: {
      icons: [blackRabitEHOT],
    },
    blackRabbit_p2p: {
      icons: [blackRabitP2P],
    },
    blackRabbit_p2p_phone: {
      icons: [blackRabbit_p2p_phone],
    },
    paycos_p2p_106: {
      icons: [paycosP2P],
    },
    wallet_expert_p2p: {
      icons: [wallet_expert_p2p],
    },
    paycos_qiwi: {
      icons: [qiwi],
    },
    paycos_yoomoney: {
      icons: [uMoney],
    },
    paycos_p2p: {
      icons: type === "withdrawal" ? [sber_tinkoff] : [p2p],
    },
    piastrix: {
      icons: [piastrix],
    },
    piastrix_sbp: {
      icons: [piastrix_sbp],
    },
    piastrix_sber: {
      icons: [piastrix_sber],
    },
    piastrix_bank: {
      icons: [piastrix_bank],
    },
    piastrix_card: {
      icons: [piastrix_card],
    },
    piastrix_sbp_sberbank: {
      icons: [piastrix_sbp_sberbank],
    },
    piastrix_sbp_tinkoff: {
      icons: [piastrix_sbp_tinkoff],
    },
    piastrix_sbp_secondary: {
      icons: [piastrix_sbp],
    },
    wallet_expert_p2p_secondary: {
      icons: [wallet_expert_p2p],
    },
    kauri: {
      icons: [
        Bitcoin,
        Ethereum,
        Litecoin,
        tether_erc20,
        tether_trc20,
        tether_bep20,
        dodgecoin,
        bnb,
      ],
      service_info: {
        ETH: Ethereum,
        "USDT-ERC20": tether_erc20,
        "USDT-TRC20": tether_trc20,
        "USDT-BEP20": tether_bep20,
        BTC: Bitcoin,
        LTC: Litecoin,
        BNB: bnb,
        DOGE: dodgecoin,
        // "USDT":
      },
    },
    cryptomus: {
      icons: [
        bnb,
        Bitcoin,
        busd,
        Ethereum,
        Litecoin,
        polygon,
        matic,
        dash,
        tron,
        tether_erc20,
        tether_trc20,
        tether_bep20,
      ],
    },
  };
}

export default function PaymentsMethods({
  setPaymentMethod,
  methods,
  type = "deposit",
}) {
  const { t } = useTranslation();
  const result = useMemo(() => {
    const result = [];

    if (!methods) {
      return result;
    }

    [...methods]
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      })
      .forEach((item) => {
        const iconsGroup = icons(type)[item.slug];

        if (iconsGroup) {
          if (item.service_info?.length > 0) {
            item.service_info.forEach((name) => {
              result.push({
                provider: item.slug,
                icon: iconsGroup?.service_info[name],
                ...item,
                currency: name,
              });
            });
          } else {
            iconsGroup.icons?.forEach((icon) => {
              result.push({
                provider: item.slug,
                icon,
                ...item,
              });
            });
          }
        }
      });

    return result;
    // eslint-disable-next-line
  }, [methods]);

  return (
    <div className={css.list}>
      {result.map((method) => (
        <PaymentItem
          key={method.icon + method.name}
          onClick={() => setPaymentMethod(method)}
          icon={method.icon}
          method={method.slug}
        />
      ))}
    </div>
  );
}
