import PaymentsMethods from "../payment-methods/payment-methods";
import css from "../payment-default.module.css";
import style from "../wallet.module.css";
import { useTranslation } from "react-i18next";
import InnerPane from "../../../components/inner-pane/inner-pane";
import WithdrawalFrom from "./withdrawal-from";
import WithdrawalFromCrypto from "./withdrawal-form-crypto";
import { useMediaQuery } from "beautiful-react-hooks";
import { useGetWithdrawalPaymentMethodsQuery } from "../../../app/api/wallet.api";

export default function Withdrawal({
  setTab,
  paymentMethod,
  setPaymentMethod,
}) {
  const { t } = useTranslation();
  const { data: paymentMethods } = useGetWithdrawalPaymentMethodsQuery();
  const provider = paymentMethod?.provider;
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (
    provider &&
    [
      "blackRabbit_acquiring",
      "blackRabbit_payout_crypto",
      "paycos_qiwi",
      "paycos_p2p",
      "paycos_yoomoney",
      "blackRabbit_p2p",
      "piastrix_sbp_sberbank",
      "piastrix_sbp_tinkoff",
      "piastrix_card",
      "piastrix_sbp_secondary",
      "wallet_expert_p2p_secondary",
    ].includes(provider)
  ) {
    return (
      <WithdrawalFrom
        setPaymentMethod={setPaymentMethod}
        paymentMethod={{
          ...paymentMethod,
          type: ["paycos_yoomoney", "paycos_qiwi"].includes(provider)
            ? "walletNumber"
            : ["piastrix_sbp_sberbank", "piastrix_sbp_tinkoff"].includes(
                  provider
                )
              ? "phone"
              : "card",
          requisitesLabel: ["paycos_yoomoney", "paycos_qiwi"].includes(provider)
            ? t("Wallet number for withdrawal")
            : t("Card number"),
        }}
        setTab={setTab}
        cardExpires={["paycos_p2p"].includes(provider)}
        cardHolder={["paycos_p2p"].includes(provider)}
      />
    );
  }
  if (provider && ["kauri", "piastrix"].includes(provider)) {
    return (
      <WithdrawalFromCrypto
        setPaymentMethod={setPaymentMethod}
        paymentMethod={{
          ...paymentMethod,
          type: provider,
          requisitesLabel:
            "kauri" === provider
              ? `${t("Crypto address")}`
              : t("Account name (piastrix)"),
        }}
        setTab={setTab}
      />
    );
  }

  const content = (
    <div className={css.wrapper}>
      {<div className={css.title}>{t("Verification withdrawal")}</div>}
      <PaymentsMethods
        setPaymentMethod={setPaymentMethod}
        methods={paymentMethods}
        type={"withdrawal"}
      />
    </div>
  );

  if (isMobile) {
    return content;
  }

  return (
    <InnerPane className={style.tab_content} paneClass={style.subpane}>
      {content}
    </InnerPane>
  );
}
