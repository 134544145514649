import css from "./profile.module.css";
import telegram from "./../../images/profile/social-telegram.svg"
import telegram_linked from "./../../images/profile/telegram_linked.svg"
import Button from "../../components/button/button";
import { useTranslation } from "react-i18next";
import Modal from "../../components/modal/modal";
import { useEffect, useRef, useState } from "react";
import Input from "../../components/form3/input";
import { useAuth } from "../../services/auth";
import { useGetQRCodeTGLinkedMutation } from "../../app/api/user.api";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import copy from "./../../images/items/copy.svg";
import {toast} from "react-toastify";

export default function Social() {
    const {t} = useTranslation();
    const auth = useAuth();
    const wrapperRef = useRef(null);
    const [copied, setCopied] = useState(false);
    const { tg_id: TGID } = auth?.user || {};
    const [linkedSocialTelegram, setLinkSocialTelegram] = useState(false);
    const [linkedTelegram, setLinkTelegram] = useState(Boolean(TGID));
    const [errorModal, setErrorModal] = useState(false);
    const [getQRCodeTGLinked, { data: data_QRCodeTG, error: error_QRCodeTG }] = useGetQRCodeTGLinkedMutation();

    const handleCopy = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    useEffect(() => {
        if (error_QRCodeTG) {

            if (error_QRCodeTG?.status === 412) {
                setErrorModal(true)
            } else {
                const notify = () => toast(t("Sorry, unknown error... Please try later"), {
                    theme: "dark",
                    type: "error"
                });
                notify();
            }
        }
    }, [error_QRCodeTG])

    useEffect(() => {
        if(data_QRCodeTG){
            setLinkSocialTelegram(true)
        }
    }, [data_QRCodeTG])

    return (
        <div className={css.social}>
            <div className={css.social_telegram}>
                <img src={linkedTelegram? telegram_linked : telegram} alt="" />
                {
                   linkedTelegram ? <div className={css.telegram_linked}>
                        <div className={css.telegram_id}>{TGID}</div>
                        <div className={css.telegram_link__confirm}>{t("linked")}</div>
                    </div> :
                    <Button 
                        variant="violet"
                        className={css.btn}
                        onClick={getQRCodeTGLinked}
                    >
                        {t("link")}
                    </Button>
                }
            </div>
            <Modal 
                isOpen={linkedSocialTelegram}
                closeModal={() => {
                    setLinkSocialTelegram(false);
                }}
                theme={"social"}
            >
                <div className={css.modal_social}> 
                    <div className={css.modal_social__telegram}>
                            <div className={css.social_text}>
                                {t("to link your telegram account, go to")}
                                <NavLink
                                    to={{pathname: "https://t.me/garillacasino_bot"}}
                                    target={"_blank"} 
                                    className={css.social_text__active}>
                                    {t("our telegram bot")}
                                </NavLink>
                                {t("and tell it the code:")}
                            </div>
                            <div className={css.telegram__wrapper__code}>
                                <Input 
                                    value={data_QRCodeTG?.["tg-key"]}
                                    className={css.code_copy}
                                />
                                <div 
                                    className={css.code_copy__img}
                                    onClick={() => handleCopy(data_QRCodeTG?.["tg-key"])}
                                    ref={wrapperRef} 
                                >
                                    <img src={copy} alt="" />
                                </div>
                                <div className={copied? css.code_copied : css.code_hiden}>{t("copied")}</div>
                            </div>
                        </div>
                    <div className={css.social_btn}>
                        <Button
                            onClick={() => {
                                setLinkSocialTelegram(false);
                                window.location.reload();
                            }}
                            variant="blue"
                        >
                            {t("close")}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={errorModal}
                closeModal={() => {
                    setErrorModal(false);
                }}
                theme={"withdrawal_small"}
            >   
                <div className={css.error_modal}>
                    <div className={css.error_title}>{t("Attention")}</div>
                    <div className={css.error_text}>{t("you must verify your account")}</div>
                    <Button
                        onClick={() => {
                            setErrorModal(false);
                        }}
                        variant="blue"
                    >
                        {t("close")}
                    </Button>
                </div>
            </Modal>
        </div>
    )
};