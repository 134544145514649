import classNames from "classnames";
import css from "./transactions.module.css";
import arrowGreen from "../../../images/bonuses/arrow-green.svg";
import arrowYellow from "../../../images/bonuses/arrow-yellow.svg";
import arrowRed from "../../../images/bonuses/arrow-red.svg";
import transfer from "../../../images/bonuses/transfer.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "beautiful-react-hooks";

export default function ExtraFields({ item }) {
  const { t } = useTranslation();
  let type;
  const isMobile = useMediaQuery("(max-width: 960px)");

  if (item.type === "replenishment") {
    type = "replenishment";
    const status = item.state_id;
    const statusName =
      status == 2 || status == 1
        ? "process"
        : status == 3
          ? "Success"
          : status == 4
            ? "reject"
            : "";

    return (
      <>
        <div className={classNames(css.status, css[type])}>
          {status == 3 && <img src={arrowGreen} alt="" />}
          {(status == 2 || status == 1) && <img src={arrowYellow} alt="" />}
          {status == 4 && <img src={arrowRed} alt="" />}
          <div className={css.status_text}>
            <div>{t("Deposit")}</div>
            <div className={css[statusName]}>{t(statusName)}</div>
          </div>
        </div>
        {isMobile && <div className={css.divider} />}
        <div className={css.source}>
          <div>
            {item.card ? (
              <>
                CARD <br /> {item.card}
              </>
            ) : (
              ""
            )}
          </div>
          <div>{item.card ? item.card : <>&mdash;</>}</div>
        </div>
      </>
    );
  } else if (item.type === "payout") {
    type = "withdarawal";

    const status = item.state_id;
    const statusName =
      status == 2 || status == 1
        ? "process"
        : status == 3
          ? "Success"
          : status == 4
            ? "reject"
            : "";

    return (
      <>
        <div className={classNames(css.status, css[type])}>
          {status == 3 && <img src={arrowGreen} alt="" />}
          {(status == 2 || status == 1) && <img src={arrowYellow} alt="" />}
          {status == 4 && <img src={arrowRed} alt="" />}
          <div>
            <div>{t("Withdrawal")}</div>
            <div className={css[statusName]}>{t(statusName)}</div>
          </div>
        </div>
        {isMobile && <div className={css.divider} />}
        <div className={css.source}>
          <div>{item.card ? "CARD" : ""}</div>
          <div>{item.card ? item.card : <>&mdash;</>}</div>
        </div>
      </>
    );
  } else if (item.type === "payout_request") {
    type = "withdarawal";

    const status = item.state_id;
    console.log("status", status);

    return (
      <>
        <div className={classNames(css.status, css[type])}>
          {(status === "approved" || status === "paid") && (
            <img src={arrowGreen} alt="" />
          )}
          {(status === "wait" || status === "verification_needed") && (
            <img src={arrowYellow} alt="" />
          )}
          {(status === "declined" ||
            status === "canceled" ||
            status === "failed") && <img src={arrowRed} alt="" />}
          <div>
            <div>{t("Withdrawal")}</div>
            <div className={css[status]}>{t(status)}</div>
          </div>
        </div>
        {isMobile && <div className={css.divider} />}
        <div className={css.source}>
          <div>{item.card ? "CARD" : ""}</div>
          <div>{item.card ? item.card : <>&mdash;</>}</div>
        </div>
      </>
    );
  } else if (item.type === "transfer") {
    const isIncome = item.type_transfer === "in";

    return (
      <>
        <div
          className={classNames(css.status, { [css.sc_deposits]: isIncome })}
        >
          <img src={transfer} alt="" />
          <div>
            <div>{t("Transfer")}</div>
            <div className={css.Success}>{t("Success")}</div>
          </div>
        </div>
        {isMobile && <div className={css.divider} />}
        <div className={css.source}>
          <div>{isIncome ? "POKER TO CASINO" : "CASINO TO POKER"}</div>
          <div>{item.login_in_poker}</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={classNames(css.status)}>
          <div>
            <div> </div>
            <div>{item.status}</div>
          </div>
        </div>
        {isMobile && <div className={css.divider} />}
        <div className={css.source}>
          <div>{item.source?.title}</div>
          <div> </div>
        </div>
      </>
    );
  }
}
