import css from "./layout.module.css";
import css_menu from "../menu/menu.module.css";
import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer/footer";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import Menu from "../menu/menu";
import classNames from "classnames";
import logo from "../../images/garilla-logo-unscreen.gif";
import { useTranslation } from "react-i18next";
import Button from "../../components/button/button";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../services/auth";
import Balance from "../../features/user/balance-2/balance";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import InternalError from "../../features/error/internal-error";
import {
  setFiltersOpened,
  setClickLogo,
  setGame,
  setStopGamesFilters,
} from "../../features/games/game.slice";
import {
  setLoginModalState,
  setOpenNotification,
  setOpenSidebar,
  setOpenUserInfo,
  setRegistrationModalState,
} from "../../app/app.slice";
import MobileMenu from "./mobile-menu/mobile-menu";
import logo_player_big from "../../images/header/logo_player_big.svg";
import Sidebar from "./sidebar";
import UserMenu from "./userMenu";
import detectDevice from "../../utils/detect-device";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import useTokenRefresh from "../../services/refresh_token";
import LanguageDrop from "./language-drop/language-drop";
import {
  useGetActiveBonusQuery,
  useGetBonusSlotQuery,
} from "../../app/api/bonus.api";
const Banners = React.lazy(() => import("./banners/banners"));

const ROUTES_WITHOUT_FOOTER = [
  /*'/vip', /*'/login', '/signup'*/
];

export default function Layout({ children, fullWidth = false }) {
  const isMobile = useMediaQuery("(max-width: 960px)");
  const isMobileLang = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(max-width: 1023px)");
  const isTabletBurger = useMediaQuery("(max-width: 1280px)");
  const { t } = useTranslation();
  const auth = useAuth();
  const location = useLocation();
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  const [showBurger, setShowBurger] = useState(!isTablet);
  const error500 = useSelector((state) => state.app.err500);
  const dispatch = useDispatch();
  const userSelectorOpen = useSelector((state) => state.app.openUserInfo);
  const notificationsSelectorOpen = useSelector(
    (state) => state.app.openNotification
  );
  const openSidebar = useSelector((state) => state.app.openSidebar);

  const { data: activeBonus } = useGetActiveBonusQuery(null, {
    skip: !auth.user,
  });

  const { data } = useGetBonusSlotQuery(null, {
    skip: !auth.user,
  });

  const listener = (event) => {
    // if (document.body.className.includes("games-page")) {
    //     const footer = document.getElementsByTagName("footer");
    //     const webim = document.querySelector("jdiv > jdiv > jdiv:first-child");
    //     let children = [...event.target.childNodes];
    //     children = children.map(a => a.offsetHeight)
    //
    //     if(
    //         footer
    //         && (
    //             children.reduce((a, b) => a + b)
    //             - event.target.scrollTop
    //             - event.target.offsetHeight
    //         ) < ( footer[0]?.offsetHeight - webim?.offsetHeight )
    //     ) {
    //         webim?.classList.remove("webim-hidden");
    //     } else {
    //         webim?.classList.add("webim-hidden");
    //     }
    // }
  };
  const openNotification = useSelector((state) => state.app.openNotification);
  const notifications = useSelector((state) => state.app.notificationsInfo);
  const notificationCount = notifications?.length;
  const scrollBarRef = useRef();
  const device = new detectDevice();
  const isMobileSafari = device.isSafariIOS;
  const Container = isMobileSafari ? React.Fragment : Scrollbar;
  const containerProps = isMobileSafari
    ? {}
    : {
        className: classNames(css.wrapper, {
          [css.noscroll]: openNotification,
        }),
        id: "wrapper",
        // onScroll: () => {},
        refLink: scrollBarRef,
      };

  useEffect(() => {
    if (!auth.user) {
      setProfileMenuOpened(false);
    }
  }, [auth.user]);

  useEffect(() => {
    setShowBurger(true);
  }, [isTabletBurger, auth.user]);

  const bodyEl = document.querySelector("body");

  const burgerClick = () => {
    dispatch(setOpenSidebar(!openSidebar));
    !openSidebar
      ? bodyEl.classList.add("noscroll")
      : bodyEl.classList.remove("noscroll");
  };

  const rootEl = document.getElementById("root");

  useEffect(() => {
    if (openNotification && rootEl) {
      rootEl.classList.add("noscroll");
    } else if (rootEl) {
      rootEl.classList.remove("noscroll");
    }
    // eslint-disable-next-line
  }, [openNotification]);

  const refreshTime = localStorage.getItem("refresh_time");
  const [currentDateTimeUTC, setCurrentDateTimeUTC] = useState(
    moment.utc().toISOString()
  );

  useEffect(() => {
    if (auth?.user) {
      const intervalId = setInterval(() => {
        setCurrentDateTimeUTC(moment.utc().toISOString());
      }, 300000);
      return () => clearInterval(intervalId);
    }
  }, [auth?.user]);

  const { refresh } = useTokenRefresh();

  const refreshToken = () => {
    refresh();
  };

  useEffect(() => {
    if (
      moment(refreshTime)
        .startOf("second")
        .isBefore(moment(currentDateTimeUTC).startOf("second"))
    ) {
      refreshToken();
      localStorage.removeItem("refresh_time");
    }
    // eslint-disable-next-line
  }, [refreshTime, currentDateTimeUTC]);

  const newAvatar =
    auth.user === null
      ? ""
      : auth.user.avatar_image === null || auth.user.avatar_image === undefined
        ? logo_player_big
        : auth.user.avatar_image;
  return (
    <div
      className={classNames(css.app, {
        [css.app_block]: !auth?.user,
        [css.safari_ios]: isMobileSafari,
      })}
    >
      <header>
        <div className={css.main}>
          <div
            data-custom-attribute="burger"
            onClick={() => burgerClick()}
            className={css.burger}
          >
            {showBurger &&
              (openSidebar ? (
                <svg
                  data-custom-attribute="burger"
                  className={css.burger_menu}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    data-custom-attribute="burger"
                    d="M1.15625 16.2441L16.3994 1.00099"
                    stroke="#DDDDDD"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    data-custom-attribute="burger"
                    d="M1.15625 1L16.3994 16.2432"
                    stroke="#DDDDDD"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  data-custom-attribute="burger"
                  className={css.burger_menu__hover}
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1H16"
                    stroke="#DDDDDD"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M1 7H16"
                    stroke="#DDDDDD"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M1 13H16"
                    stroke="#DDDDDD"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              ))}
          </div>
          <div
            className={classNames("container-full container-full-header", {
              container: isTablet,
            })}
          >
            <Sidebar activeBonus={activeBonus} />
            <div className={css.logo}>
              <NavLink
                to={"/"}
                exact={true}
                onClick={(e) => {
                  dispatch(setFiltersOpened(null));
                  dispatch(setStopGamesFilters(true));
                  localStorage.removeItem("filters");
                  dispatch(setGame(null));
                  dispatch(setClickLogo(true));
                  if (location.pathname === `/`) {
                    e.preventDefault();
                    window.location.reload();
                    try {
                      scrollBarRef?.current.scrollTop();
                    } catch (e) {}
                  } else {
                    window.openSplash();
                  }
                }}
                className={"ignore-active"}
              >
                <img src={logo} alt="" />
              </NavLink>
            </div>

            <Menu
              onBurgerClick={() => setProfileMenuOpened(!profileMenuOpened)}
              activeBonus={activeBonus}
              bonuses={data}
            />

            <div className={classNames(css.user_actions)}>
              {auth?.isLoading ? (
                <Loader />
              ) : auth.user ? (
                <>
                  <div className={css.authorized}>
                    <div className={css.balance}>
                      <Balance />
                    </div>
                    {auth?.user && (
                      <div
                        className={css_menu.app_menu__notification}
                        data-custom-attribute="notification"
                        onClick={() => {
                          dispatch(
                            setOpenNotification(!notificationsSelectorOpen)
                          );
                        }}
                      >
                        <div
                          data-custom-attribute="notification"
                          className={classNames(css_menu.notif_icon, {
                            [css_menu.notif_icon_active]:
                              notificationsSelectorOpen,
                          })}
                        ></div>
                        {Boolean(notificationCount) && (
                          <div className={css.svg_circle}>
                            <span className={css.svg_circle__text}>
                              {notificationCount}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={css.player}>
                      <div
                        className={css.item}
                        data-custom-attribute="user-acc"
                        onClick={() => {
                          dispatch(setOpenUserInfo(!userSelectorOpen));
                        }}
                      >
                        <img
                          src={newAvatar}
                          className={css.logo_player}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={css.user_actions_log}>
                  <Button
                    onClick={() => {
                      dispatch(setRegistrationModalState(true));
                    }}
                    variant={"violet"}
                    className={classNames(
                      css.reg_btn,
                      css.auth_btns,
                      css.auth_btns_style
                    )}
                  >
                    {t("Registration")}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(setLoginModalState(true));
                    }}
                    className={classNames(
                      css.login_btn,
                      css.auth_btns,
                      css.auth_btns_style
                    )}
                  >
                    {t("Sign in")}
                  </Button>
                </div>
              )}
              {!isMobileLang && <LanguageDrop />}
            </div>
          </div>
        </div>
      </header>
      <main>
        <div
          className={classNames(css.top_decoration, {
            hidden:
              location.pathname === "/" || location.pathname.includes("/games"),
          })}
        />
        {auth.user && <UserMenu activeBonus={activeBonus} bonuses={data} />}
        <Container {...containerProps}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Banners />
          </React.Suspense>
          <div
            className={classNames(css.container, {
              container: !fullWidth,
              "container-full": isMobile,
              "container-ios": isMobileSafari,
            })}
          >
            <div>{error500 ? <InternalError /> : children}</div>
          </div>
          {!ROUTES_WITHOUT_FOOTER.includes(location.pathname) && <Footer />}
        </Container>
      </main>
      {auth.user || isMobile ? (
        <MobileMenu activeBonus={activeBonus} bonuses={data} />
      ) : (
        <></>
      )}
    </div>
  );
}
